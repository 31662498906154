import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from 'angular-web-storage';
import { UserLoginService } from '../../services/user-login.service';
import { environment} from '../../../environments/environment';
import { RolService } from 'src/app/services/rol.service';
import { GLOBALS } from 'src/app/shared/globals.shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/auth.shared';
import { HttpErrorResponse } from '@angular/common/http';
import { UserDataLocalStorageModel } from 'src/app/models/userDataLocalStorage';
import { CognitoUtilService } from '../../services/cognito.service';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
    providers: [AuthService]
})
export class AuthComponent implements OnInit {

    @ViewChild('refModalSeleccionarClienteComercial', { static: false }) modalSeleccionarClienteComercial: ElementRef;
    empresas: Array<{codigo: number, nombre: string}> = [];
    clientesComercial: Array<{codigo: number, nombre: string}> = [];
    clienteComercialSeleccionado: {codigo: number, nombre: string};
    usuarioId: number;
    navigate: Array<string> = ['/'];
    queryParams: {};
    tituloModal = 'Cliente Comercial';

    constructor(
      public cognitoUtil: CognitoUtilService,
      private  router: Router,
      private spinner: NgxSpinnerService,
      private localStorageService: LocalStorageService,
      private userLoginService: UserLoginService,
      private rolService: RolService,
      private modalService: NgbModal,
      private toastr: ToastrService,
      private authService: AuthService
      ) {
        console.log('AuthComponent: constructor');
    }

    ngOnInit() {
        this.userLoginService.isAuthenticated(this);
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
      console.log('auth: verificacion sesion');
      if (isLoggedIn) {
        const permisosModulosAcciones = this.localStorageService.get('PERMISOSMODULOSACCIONES') || {};
        if (JSON.stringify(permisosModulosAcciones) === '{}') {
          this.login();
        } else {
          this.persistirCliComSeleccionadoEntreSistemas();
        }
      } else {
        this.login();
      }
    }

    private async persistirCliComSeleccionadoEntreSistemas(): Promise<void>  {
        try {
            // /auth#...clicom=xxx&navigate=xxx
            const params = new URLSearchParams(this.router.url.split('#')[1]);
            this.navigate = [params.get('navigate') || '/'];
            const paramCliCom: any = params.get('clicom');
            const paramEmp: any = params.get('emp');
            const accessToken = params.get('access_token');
            const idToken = params.get('id_token');
            const rtoken = params.get('rt');
            const ud = params.get('ud');
            const ci = params.get('ci');
            this.localStorageService.set('ud', ud);
            this.localStorageService.set('ci', ci);

            const idx = this.router.url.lastIndexOf('navigate')
            if(idx != -1){
                const [, ...queryParams] = this.router.url.slice(idx).split('&')
                queryParams.forEach((param) => {
                    const k = param.slice(0,param.indexOf('='))
                    const v = param.slice(param.indexOf('=') + 1);
                    this.queryParams = { ...this.queryParams, [k]:v}
                })
            }

            if (paramCliCom) {
              let userData = null;
              if (params.has('ud')) {
                userData = JSON.parse(atob(params.get('ud')));
              }
              this.localStorageService.set('userData', {
                  rut: userData.rut + '-' + userData.dv,
                  nombre: userData.nombre,
                  email: userData.email,
                  now: +new Date(),
                  // rutHolding: infoUser.rutHolding,
                }
                , 0, 's');

              await this.validaToken(accessToken, idToken, userData, rtoken, paramCliCom, paramEmp);
            } else {
              const userDataAux = this.localStorageService.get('userData');

              console.log('else paramCliCom');
              if ( userDataAux.empresas ) {
                await this.router.navigate(this.navigate,{
                    queryParams: { 
                        ...this.queryParams
                    }
                });
              } else {
                this.validarClienteComercial( userDataAux.email);
              }
            }
        } catch (error) {
            console.error(error);
            this.router.navigate(['/']);
        }
    }


    async login() {
        this.spinner.show();
        localStorage.clear();

        const params = new URLSearchParams(this.router.url.split('#')[1]);

        this.navigate = [params.get('navigate') || '/'];

        const accessToken = params.get('access_token');
        const idToken = params.get('id_token');
        const rtoken = params.get('rt');
        const ud = params.get('ud');
        const paramCliCom: any = params.get('clicom');
        const paramEmp: any = params.get('emp');
        this.localStorageService.set('ud', ud);
        this.localStorageService.set('ci', params.get('ci') ? params.get('ci') : environment.CLIENT_ID);
        let userData = null;

        //añadir informacion adicional a la ruta
        const idx = this.router.url.lastIndexOf('navigate')
        if(idx != -1){
            const [, ...queryParams] = this.router.url.slice(idx).split('&')
            queryParams.forEach((param) => {
                const k = param.slice(0,param.indexOf('='))
                const v = param.slice(param.indexOf('=') + 1);
                this.queryParams = { ...this.queryParams, [k]:v}
            })
        }
        if (params.has('ud')) {
            userData = JSON.parse(atob(params.get('ud')));
        }

        await this.validaToken(accessToken, idToken, userData, rtoken, paramCliCom, paramEmp);

    }

  validaToken(accessToken, idToken, userData, rtoken, paramCliCom, paramEmp) {
      this.userLoginService.validateToken({
      access_token: accessToken,
      id_token: idToken
    }).subscribe(async (result) => {
      console.log(result);
      this.localStorageService.set('ci', result.aud);
      this.setCookies(idToken, accessToken, result, userData, rtoken);
      this.validarClienteComercial( userData.email, paramCliCom , paramEmp);
      this.spinner.hide();
    }, error => {
      console.log(error);
      this.spinner.hide();
      window.location.href = `${environment.URL_SIGNOUT}?callback=${environment.URL_AUTH}&code=${environment.CLIENT_ID}`;
    });
    // return this.userLoginService.validateToken({access_token:accessToken, id_token:idToken}).toPromise()
    //   .then((result) => {
    //     console.log(result);
    //     this.localStorageService.set('ci', result.aud);
    //     this.setCookies(idToken, accessToken, result, userData, rtoken);
    //     return this.validarClienteComercial(userData.email, paramCliCom, paramEmp);
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //     window.location.href = `${environment.URL_SIGNOUT}?callback=${environment.URL_AUTH}&code=${environment.CLIENT_ID}`;
    //   })
  }

  async validarClienteComercial( email: string, paramCliCom = null, paramEmp = null ) {
    await this.obtenerClienteComercial(email);
    await this.authService.cargarAccionesActivas(this.usuarioId);
    if (this.localStorageService.get('PERMISOSMODULOSACCIONES').LOGIN !== undefined) {
      console.log('Buscar Empresas cuando tiene la opcion habilitada');
      await this.obtenerEmpresasPorUsuarioEmail(email);
      this.tituloModal = 'Empresa';
      if (this.empresas.length === 1) {
        this.clienteComercialSeleccionado = this.clientesComercial.find(c => c.codigo === this.empresas[0].codigo);
      }
    }
    if (paramCliCom) {
      paramCliCom = Number(paramCliCom);
      if (this.localStorageService.get('PERMISOSMODULOSACCIONES').LOGIN !== undefined) {
        // tslint:disable-next-line: max-line-length
        this.clienteComercialSeleccionado = this.empresas.find(c => c.nombre.split(' - ')[0].toString() === paramEmp.toString());
      } else {
        this.clienteComercialSeleccionado = this.clientesComercial.find(c => c.codigo === paramCliCom);
      }
    }

    if (this.clienteComercialSeleccionado) {
      this.confirmarClienteComercial();
    } else {
      if (this.clientesComercial.length > 1) {
        this.spinner.hide();
        // tslint:disable-next-line: max-line-length
        debugger;
        this.modalService.open(this.modalSeleccionarClienteComercial, { size: 'lg', backdrop: 'static', keyboard: false });
      } else if (this.clientesComercial.length === 1) {
        this.confirmarClienteComercial();
      } else {
        throw new Error('No hay Cliente/s Comercial');
      }
    }
  }
    setCookies(idToken, accessToken, infoUserCognito, infoUser, refreshToken) {
        const keyPrefix = `CognitoIdentityServiceProvider.${infoUserCognito.aud}`;

        const idTokenKey = `${keyPrefix}.${infoUserCognito['cognito:username']}.idToken`;
        const accessTokenKey = `${keyPrefix}.${infoUserCognito['cognito:username']}.accessToken`;
        const lastUserKey = `${keyPrefix}.LastAuthUser`;
        const refreshTokenKey = `${keyPrefix}.${infoUserCognito['cognito:username']}.refreshToken`;

        localStorage.setItem(idTokenKey, idToken);
        localStorage.setItem(accessTokenKey, accessToken);
        localStorage.setItem(lastUserKey, infoUserCognito['cognito:username']);
        localStorage.setItem(refreshTokenKey, refreshToken);
        if (infoUser) {
            this.localStorageService.set('userData', {
                rut: infoUser.rut + '-' + infoUser.dv,
                nombre: infoUser.nombre,
                email: infoUser.email,
                now: +new Date()
            }, 0, 's');
        }

        this.localStorageService.set('idToken', idToken);
        this.localStorageService.set('accessToken', accessToken);
        this.localStorageService.set('refreshToken', refreshToken);
    }

    confirmarClienteComercial() {
        if (this.clienteComercialSeleccionado) {
            this.spinner.show();
            Promise.all([
                this.authService.cargarPermisosCliCom(this.usuarioId, this.clienteComercialSeleccionado.codigo)
            ])
                .then(() => {
                    // guarda en storage
                    const userData: UserDataLocalStorageModel = this.localStorageService.get('userData');
                    userData.usuarioId = this.usuarioId;
                    userData.clientesComercial = this.clientesComercial;
                    userData.empresas = this.empresas;
                    // tslint:disable-next-line: max-line-length
                    userData.clienteComercialSeleccionado = this.clientesComercial.find(x => x.codigo === this.clienteComercialSeleccionado.codigo);
                    userData.empresaSeleccionada = this.clienteComercialSeleccionado;
                    this.localStorageService.set('userData', userData);

                    if (this.clientesComercial.length > 1) {
                        this.toastr.success(`Cliente comercial ${userData.clienteComercialSeleccionado.nombre} seleccionado`);
                    }

                    this.modalService.dismissAll();
                    this.router.navigate(this.navigate,{
                      queryParams: { 
                        ...this.queryParams
                    }
                    });
                }).catch((error: any) => {
                    console.log(error);
                    this.toastr.error('Ocurrio un error al obtener Permisos.');
                }).finally(() => {
                    this.spinner.hide();
                });
        } else if (this.clientesComercial.length === 1) {
          Promise.all([
            this.authService.cargarPermisosCliCom(this.usuarioId, this.clientesComercial[0].codigo)
          ])
            .then(() => {
              // guarda en storage
              const userData: UserDataLocalStorageModel = this.localStorageService.get('userData');
              userData.usuarioId = this.usuarioId;
              userData.clientesComercial = this.clientesComercial;
              userData.empresas = this.empresas;
              // tslint:disable-next-line: max-line-length
              userData.clienteComercialSeleccionado = this.clientesComercial[0];
              userData.empresaSeleccionada = this.clientesComercial[0];
              this.localStorageService.set('userData', userData);

              if (this.clientesComercial.length > 1) {
                this.toastr.success(`Cliente comercial ${userData.clienteComercialSeleccionado.nombre} seleccionado`);
              }

              this.modalService.dismissAll();

              this.router.navigate(this.navigate,{
                queryParams: { 
                  ...this.queryParams
              }
              });
            }).catch((err) => {
            this.toastr.error('Ocurrio un error al obtener Permisos.');
          }).finally(() => {
            this.spinner.hide();
          });
        } else {
          const tipoSeleccion =
              this.localStorageService.get('PERMISOSMODULOSACCIONES').LOGIN !== undefined ? 'una Empresa' : 'un Cliente Comercial';
          this.toastr.error(`Debe seleccionar ${tipoSeleccion}`);
      }
    }

    obtenerEmpresasPorUsuarioEmail(usuarioEmail: string): Promise<any> {
      return this.rolService.obtenerEmpresasPorUsuarioEmail(usuarioEmail).toPromise()
            .then((response) => this.empresas = response.usuario.empresas);
  }

  async obtenerClienteComercial( email ) {
    try{
      const response = await this.rolService.obtenerClientesComercialPorUsuarioEmail( email ).toPromise();
        this.usuarioId = response.usuario.id;
        this.clientesComercial = response.usuario.clicom;
    }catch (error){
      console.log(error);
      this.spinner.hide();
      this.toastr.error('Ocurrio un error al obtener Cliente Comercial.');
    }
  }
}
