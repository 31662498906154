<main style="background-color: #266FE0 ; height: 100vh;">
</main>

<ng-template #refModalSeleccionarClienteComercial let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Seleccionar {{tituloModal}}</h4>
    </div>
    <div class="modal-body">
        <kendo-dropdownlist *ngIf="clientesComercial.length > 1 && empresas.length === 0" class="form-control" [(ngModel)]="clienteComercialSeleccionado" [data]="clientesComercial" [textField]="'nombre'" [valueField]="'codigo'" [filterable]="true" [kendoDropDownFilter]="{ caseSensitive: false, operator: 'contains' }">
            <kendo-combobox-messages noDataText="Sin resultados">
            </kendo-combobox-messages>
        </kendo-dropdownlist>

        <kendo-dropdownlist *ngIf="empresas.length > 1" class="form-control" [(ngModel)]="clienteComercialSeleccionado" [data]="empresas" [textField]="'nombre'" [valueField]="'codigo'" [filterable]="true" [kendoDropDownFilter]="{ caseSensitive: false, operator: 'contains' }">
            <kendo-combobox-messages noDataText="Sin resultados">
            </kendo-combobox-messages>
        </kendo-dropdownlist>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="confirmarClienteComercial()">Aceptar</button>
    </div>
</ng-template>