import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';
import { CheckableSettings, TreeItemLookup } from '@progress/kendo-angular-treeview';
import { Observable, of } from 'rxjs';
import * as moment from 'moment';
import { Workbook } from '@progress/kendo-ooxml';
import { saveAs } from '@progress/kendo-file-saver';
const lzwCompress = require('lzwcompress');
import {UserDataLocalStorageModel} from 'src/app/models/userDataLocalStorage';
import {ClienteAuditoria} from 'otic-cliente-auditoria';
// Services
import { UserLoginService } from '../../services/user-login.service';
import { ClienteComercialService } from '../../services/cliente-comercial.service';

// Models
import { EmpresasSucursalModel } from 'src/app/models/empresasSucursal.model';

// Shared
import { environment } from '../../../environments/environment';
import { GlobalService, GLOBALS } from '../../shared/globals.shared';
import { ConsolidadoService } from '../../services/consolidado.service';
import { PermisosCliComLocalStorageModel } from 'src/app/models/permisosCliComLocalStorage';

@Component({
  selector: 'app-rep-consolidado-cursos',
  templateUrl: './rep-consolidado-cursos.component.html',
  styleUrls: ['./rep-consolidado-cursos.component.scss'],
  providers: [GlobalService]
})
export class RepConsolidadoCursosComponent implements OnInit {
  urlGestion: string = environment.url_gestor;

  min: Date = null;
  max: Date = null;
  fechaActual: string = [
    new Date().getDate().toString().length === 1 ? '0' + new Date().getDate().toString() : new Date().getDate().toString(),
    (new Date().getMonth() + 1).toString().length === 1 ?
      '0' + (new Date().getMonth() + 1).toString() : (new Date().getMonth() + 1).toString(),
    new Date().getFullYear()].join('/');

  sinPVs = false;

  empresasSucursales: any[] = [];
  parsedData: any[] = [];
  checkedKeys: any[] = [];
  searchTerm = '';
  listadoFinal: any;
  userData: UserDataLocalStorageModel = {};
  empresasSucursalSeleccionadas: number[] = [];
  empresas: EmpresasSucursalModel[] = [];
  prevEmpresasSucursalSeleccionadas: any [] = [];
  fecIni: Date = null;
  fecFin: Date = null;
  nombreCC: string;
  errorFiltro = {
    empresaSucursal: false,
    fecIni: false,
    fecFin: false
  };

  listFecIni: Array<string> = [];
  listFecFin: Array<string> = [];

  consolidado: any[] = [];
  camposAdicACCabecera: any[] = [];
  camposAdicAC: any[] = [];
  camposAdicPartCabecera: any[] = [];
  camposAdicPart: any[] = [];
  permisosModulosAcciones: any = {};
  permisos: Array<PermisosCliComLocalStorageModel>;

  checkAnulados = false;
  checkCASeparados = false;
  checkCASoloCC = false;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private clienteComercialService: ClienteComercialService,
    private userLoginService: UserLoginService,
    private consolidadoService: ConsolidadoService) {
    console.log('RepAportesImputadosComponent: constructor');
  }

  /**
   * OnInit Event
   */
  ngOnInit() {
    console.log('RepAportesImputadosComponent: OnInit');

    this.userLoginService.isAuthenticated(this);
    this.userData = this.localStorageService.get('userData');
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });

      this.permisosModulosAcciones = this.localStorageService.get('PERMISOSMODULOSACCIONES') || {};
      this.permisos = this.localStorageService.get('permisos');

      if (this.permisosModulosAcciones.REPCUR) {
        this.initListadoEmpresas();
      } else {
        this.toastr.error(`No estás autorizado para ingresar a esta página`, GLOBALS.TOASTER.TITLE_ERROR);
        this.router.navigate(['/']);
      }

    }
  }

  /**
   * Inicializa el listado de Empresas
   */
  initListadoEmpresas() {
    this.spinner.show();

    const sucursalesAutorizadas = this.permisos.map((item) => item.data.sucursalCodigo).join(',');

    if (sucursalesAutorizadas) {
      this.clienteComercialService.listarEmpresasSucursales(sucursalesAutorizadas)
        .subscribe(async (result) => {

            if (result.rows.length > 0) {
              this.empresas = result.rows.map((empresa) => new EmpresasSucursalModel(empresa));

              let index = 1;
              let codigoEmpresa: number;
              let rama: any;
              for (const empresa of this.empresas) {
                if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa === empresa.codigoSucursal) {
                  if (index !== 1) {
                    this.empresasSucursales.push(rama);
                  }

                  codigoEmpresa = empresa.codigoEmpresa;
                  rama = {
                    text: empresa.rutRazonSocial,
                    value: empresa.codigoEmpresa,
                    items: []
                  };
                } else if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa !== empresa.codigoSucursal) {
                  if (index !== 1) {
                    this.empresasSucursales.push(rama);
                  }

                  codigoEmpresa = empresa.codigoEmpresa;
                  rama = {
                    text: empresa.rutRazonSocial,
                    items: [{
                      text: empresa.sucursal,
                      value: empresa.codigoEmpresa
                    }]
                  };
                } else {
                  rama.items.push({
                    text: empresa.sucursal,
                    value: empresa.codigoEmpresa
                  });
                }

                if (index === this.empresas.length) {
                  this.empresasSucursales.push(rama);
                }

                index++;
              }

              this.parsedData = this.empresasSucursales;
              console.log('this.parsedData', this.parsedData);
              this.listadoFinal = [{
                text: 'Seleccionar Todos',
                value: 'Todos',
                items: this.parsedData.map(x => {
                  return {
                    text: x.text,
                    value: x.value,
                    items: x.items
                  };
                })
              }];
              console.log('prueba', this.listadoFinal);

            } else {
              this.toastr.warning(`No hay información de Empresas / Sucursales para Reportes`, `Advertencia`);
            }

            this.spinner.hide();
          }, error => {
            console.log(error);
            this.spinner.hide();
            this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
          }
        );
    } else {
      this.toastr.warning(`No hay Empresas / Sucursales en Cliente Comercial`, `Advertencia`);
      this.spinner.hide();
    }
  }


  /**
   * Busca las fechas de Inicio y Fin de las PV al cambiar la selección
   */
  inicializaFechas() {
    this.fecIni = null;
    this.fecFin = null;
    this.sinPVs = false;

    this.errorFiltro = {
      empresaSucursal: false,
      fecIni: false,
      fecFin: false
    };

    if (this.empresasSucursalSeleccionadas.length > 0) {
      const rutsEmpresa = this.empresasSucursalSeleccionadas.join(',');
      // const rutsEmpresa = `'88566900-K', '96731890-6'`;

      this.spinner.show();
      this.consolidadoService.obtenerFechasPVs(rutsEmpresa).subscribe(
        (result: any) => {
          this.spinner.hide();

          if (result.minanio === null) {
            this.sinPVs = true;
          } else {

            this.min = new Date(result.minanio.substring(0, 19));
            this.max = new Date(result.maxanio.substring(0, 19));

            this.fecIni = this.min;
            this.fecFin = this.max;
            // this.min = result.minanio.toString();
            // this.max = result.maxanio.toString();

            // this.fecIni = this.min;
            // this.fecFin = this.max;

            // this.listFecIni = [];
            // this.listFecFin = [];
            // for (let z = this.min; z <= this.max; z++) {
            //   this.listFecIni.push(z.toString());
            //   this.listFecFin.push(z.toString());
            // }
          }
        },
        error => {
          console.log(error);
          this.spinner.hide();
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      );
    } else {
      if (this.empresasSucursales.length > 0) {
        this.errorFiltro.empresaSucursal = true;
      }
    }
  }

  /**
   * Configura las fechas de inicio y fin seleccionables cuando cambia el valor
   */
  configuraFechas() {
    // this.spinner.show();

    // this.listFecIni = [];
    // this.listFecFin = [];

    // for (let z = this.min; z <= (this.fecFin !== undefined ? this.fecFin : this.max); z++) {
    //   this.listFecIni.push(z.toString());
    // }

    // for (let z = (this.fecIni !== undefined ? this.fecIni : this.min); z <= this.max; z++) {
    //   this.listFecFin.push(z.toString());
    // }

    // this.spinner.hide();
  }

  seleccionarEmpresasSucursales(sucursales:number[]){
    this.empresasSucursalSeleccionadas = sucursales;
    this.inicializaFechas();
  }

  /**
   * Filtra el Reporte
   */
  filtrarReporte() {
    this.errorFiltro = {
      empresaSucursal: false,
      fecIni: false,
      fecFin: false
    };

    if (this.empresasSucursalSeleccionadas.length === 0) {
      this.errorFiltro.empresaSucursal = true;
      return;
    } else {
      if (this.fecIni === undefined || this.fecIni === null) {
        this.errorFiltro.fecIni = true;
      }
      if (this.fecFin === undefined || this.fecFin === null) {
        this.errorFiltro.fecFin = true;
      }

      if (this.fecIni === undefined || this.fecIni === null || this.fecFin === undefined || this.fecFin === null) {
        return;
      }
    }

    this.nombreCC = this.userData.clienteComercialSeleccionado.nombre;
    const filtrosReporte = {
      cliComercial:this.nombreCC,
      empresaSucursal: this.empresasSucursalSeleccionadas.join(','),
      // empresaSucursal: `'88566900-K', '93658000-9'`,
      fecIni: moment(this.fecIni).format('L'),
      fecFin: moment(this.fecFin).format('L'),
      usuario: this.userData.rut,
      email: this.userData.email,
      anulados: this.checkAnulados,
      caseparados: this.checkCASeparados,
      cacc: this.checkCASoloCC
    };

    this.spinner.show();
    this.consolidadoService.reporteConsolidadoCursosStart(filtrosReporte).subscribe(
      (result: any) => {
        console.log('Proceso iniciado');
        this.accion('Reporte Consolidado de Cursos','', 'Genera Reporte para '+this.nombreCC);
        this.toastr.success(result, GLOBALS.TOASTER.TITLE_OK);
        this.spinner.hide();
      },
      error => {
        console.log('No es posible descargar el archivo', error);
        this.spinner.hide();
        this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
      }
    );
  }

  /**
   * Metodo para exportar a Excel
   * @param grid Grid
   */
  public exportToExcel(): void {
    this.spinner.show();

    const workbook = new Workbook({
      sheets: [{
        columns: [],
        rows: [{ cells: [{}] }]
      }]
    });

    workbook.options.sheets[0].name = 'Consolidado';

    const columnas = Object.keys(this.consolidado[0]);

    workbook.options.sheets[0].rows[0].cells[0].colSpan =
      columnas.length + this.camposAdicACCabecera.length - 1  + this.camposAdicPartCabecera.length - 1;
    workbook.options.sheets[0].rows[0].cells[0].value = `Fecha Informe: ${this.fechaActual}`;

    workbook.options.sheets[0].rows.push({ cells: [] });

    let index = 0;
    for (const valor of columnas) {
      let columna: string;
      columna = valor;

      if (index === 31) {
        for (const nombreCAAC of this.camposAdicACCabecera) {
          columna = nombreCAAC.nombre;
          workbook.options.sheets[0].rows[1].cells.push({
            background: '#184D9B',
            bold: true,
            color: '#ffffff',
            value: columna
          });
          workbook.options.sheets[0].columns.push({ autoWidth: true });
        }
      } else if (index === 56) {
        for (const nombreCAPart of this.camposAdicPartCabecera) {
          columna = nombreCAPart.nombre;
          workbook.options.sheets[0].rows[1].cells.push({
            background: '#184D9B',
            bold: true,
            color: '#ffffff',
            value: columna
          });
          workbook.options.sheets[0].columns.push({ autoWidth: true });
        }
      } else {
        switch (valor) {
          case 'nropv': columna = 'Nº PV'; break;
          case 'tipodocumento': columna = 'Tipo de Documento'; break;
          case 'cuentafinanciamiento': columna = 'Cuenta de Financiamiento'; break;
          case 'tipocurso': columna = 'Tipo de Curso'; break;
          case 'modalidadcapacitacion': columna = 'Modalidad de Capacitación'; break;
          case 'modalidadcapacitaciondesc': columna = 'Modalidad: Descripción'; break;
          case 'clasepedido': columna = 'Clase de Pedido'; break;
          case 'clasepedidodesc': columna = 'Clase de Pedido: Descripción'; break;
          case 'modalidadinstruccion': columna = 'Modalidad de Instrucción'; break;
          case 'modalidadinstrucciondesc': columna = 'Modalidad de Instrucción: Descripción'; break;
          case 'fechainicio': columna = 'Fecha Inicio'; break;
          case 'fechatermino': columna = 'Fecha Termino'; break;
          case 'viaticosrendidos': columna = 'Viáticos Rendidos'; break;
          case 'asistenciarendida': columna = 'Asistencia Rendida'; break;
          case 'codigosence': columna = 'Código Sence'; break;
          case 'nombrecurso': columna = 'Nombre del Curso'; break;
          case 'horasautorizadas': columna = 'Horas Autorizadas'; break;
          case 'horasrealizadas': columna = 'Horas Realizadas'; break;
          case 'statusdescripcion': columna = 'Status Descripción'; break;
          case 'statusrechazo': columna = 'Status Rechazo'; break;
          case 'nombreotec': columna = 'Nombre OTEC'; break;
          case 'sedeotec': columna = 'Sede OTEC'; break;
          case 'nroparticipantes': columna = 'Nº Participantes'; break;
          case 'costocursootic': columna = 'Costo Curso OTIC'; break;
          case 'costoviaticootic': columna = 'Costo Viatico OTIC'; break;
          case 'costotrasladootic': columna = 'Costo Traslado OTIC'; break;
          case 'costocursoempresa': columna = 'Costo Curso Empresa'; break;
          case 'costoviaticoempresa': columna = 'Costo Viatico Empresa'; break;
          case 'costotrasladoempresa': columna = 'Costo Traslado Empresa'; break;
          case 'comision': columna = 'Comisión'; break;
          case 'complementaria': columna = 'Complementaria'; break;
          // Campos AC - Posicion 31
          case 'nombreholding': columna = 'Nombre Holding'; break;
          case 'clientefinancia': columna = 'Cliente que Franquicia'; break;
          case 'sucursal': columna = 'Sucursal'; break;
          case 'areaconocimientocurso': columna = 'Area de Conocimiento Curso'; break;
          case 'especialidadcurso': columna = 'Especialidad curso'; break;
          case 'ciudadejecucion': columna = 'Ciudad de Ejecución'; break;
          case 'region': columna = 'Región'; break;
          case 'direccionejecucion': columna = 'Dirección Lugar de Ejecución'; break;
          case 'unohistorico': columna = '1 % Historico'; break;
          case 'dnc': columna = 'Detección de Nececidades'; break;
          case 'cbp': columna = 'Comité Bipartito'; break;
          case 'rut': columna = 'Rut'; break;
          case 'codtipodocumento': columna = 'Cod.Tipo de Documento'; break;
          case 'nombre': columna = 'Nombre'; break;
          case 'appaterno': columna = 'Ap.Paterno'; break;
          case 'apmaterno': columna = 'Ap.Materno'; break;
          case 'viaticosolicitado': columna = 'Viatico Solicitado'; break;
          case 'viaticorendido': columna = 'Viatico Rendido'; break;
          case 'trasladosolicitado': columna = 'Traslado Solicitado'; break;
          case 'trasladorendido': columna = 'Traslado Rendido'; break;
          case 'diasvyt': columna = 'Dias VyT'; break;
          case 'franquicia': columna = 'Franquicia'; break;
          case 'asistencia': columna = 'Asistencia'; break;
          case 'calificacion': columna = 'Calificacion'; break;
          case 'evaluacion': columna = 'Evaluacion'; break;
          // Campos Participantes - Posicion 56
          case 'rechazo': columna = 'Rechazo'; break;
          case 'motivorechazo': columna = 'Motivo de Rechazo'; break;
          case 'costocursooticparticipante': columna = 'Costo Curso OTIC por Participante'; break;
          case 'costoviaticooticparticipante': columna = 'Costo Viatico OTIC por Participante'; break;
          case 'costotrasladooticparticipante': columna = 'Costo Traslado OTIC por Participante'; break;
          case 'costocursoempresaparticipante': columna = 'Costo Curso Empresa por Participante'; break;
          case 'costoviaticoempresaparticipante': columna = 'Costo Viatico Empresa por Participante'; break;
          case 'costotrasladoempresaparticipante': columna = 'Costo Traslado Empresa por Participante'; break;
          case 'comisionparticioante': columna = 'Comision'; break;
          case 'condicion': columna = 'Condicion'; break;
          case 'fechadesvinculacion': columna = 'Fecha Desvinculación'; break;
          case 'nivelocupacional': columna = 'Nivel Ocupacional'; break;
          case 'nivelescolaridad': columna = 'Nivel de Escolaridad'; break;
          case 'sexo': columna = 'Sexo'; break;
          case 'totalcostootic': columna = 'Total Costo OTIC'; break;
          case 'totalcostoempresa': columna = 'Total Costo Empresa'; break;
          case 'solt': columna = 'Nº Solicitud'; break;
        }

        workbook.options.sheets[0].rows[1].cells.push({
          background: '#112540',
          bold: true,
          color: '#ffffff',
          value: columna
        });
        workbook.options.sheets[0].columns.push({ autoWidth: true });
      }

      index++;
    }

    for (const obj of this.consolidado) {
      const celdas: any[] = [];

      index = 0;
      for (const valor of columnas) {
        if (index === 31) {
          for (const nombreCAAC of this.camposAdicACCabecera) {
            const valorCA = this.camposAdicAC.find(x =>
              parseInt(x.pv, 10) === parseInt(obj.nropv, 10) &&
              x.nombre.toString() === nombreCAAC.nombre.toString());

            celdas.push({
              value: valorCA !== null && valorCA !== undefined ? valorCA.valor : null
            });
          }
        } else if (index === 56) {
          for (const nombreCAPart of this.camposAdicPartCabecera) {
            const valorPart = this.camposAdicPart.find(x =>
              parseInt(x.pv, 10) === parseInt(obj.nropv, 10) &&
              x.rut.toString() === obj.rut.toString() &&
              x.nombre.toString() === nombreCAPart.nombre.toString());

            celdas.push({
              value: valorPart !== null && valorPart !== undefined ? valorPart.valor : null
            });
          }
        } else {
          celdas.push({
            value: obj[valor]
          });
        }

        index++;
      }

      workbook.options.sheets[0].rows.push({
        cells: celdas
      });
    }

    this.spinner.hide();

    workbook.toDataURL().then(dataUrl => {
      saveAs(dataUrl, 'Consolidado.xlsx');
    });
  }

  /**
   * Filtra Reporte al presionar tecla Enter
   * @param event Evento
   */
  filtrarReporteKeydown(event) {
    if (event.key === 'Enter') {
      this.filtrarReporte();
    }
  }

  async accion(modulo: string, submodulo: string, acc: string){
    const token = await this.localStorageService.get('idToken');
    console.log('token',token);

    ClienteAuditoria({
      sistema: 'GCH Reportes',
      modulo,
      submodulo: submodulo ? submodulo : '',
      usuario: this.userData.rut,
      token,
      accion: acc
    });
  }

}
