<main>
    <div class="container-fluid mb-4 fondo-gris-ok" style="margin-top:-20px;">
        <div class="container">
            <div class="row">
                <div class="col-md-7 col-12 mt-3">
                    <h1>Reporte Resumen Cartola</h1>
                </div>
                <div class="col-md-5 col-12">
                    <div aria-label="breadcrumb" class="text-right float-right">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href={{urlGestion}}>Home</a></li>
                            <li class="breadcrumb-item"><a [routerLink]="['/home-reportes']">Reportes</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Resumen Cartola</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-12 form-group">
                    <div class="row">
                        <div class="col-12">
                            <label>Selecciona una o varias Empresas y/o Sucursales y el Período a consultar, luego presiona la tecla Enter o el botón con el icono de Lupa:</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-5">
                            <app-selector-empresa [empresas]="empresas" (seleccionEmpresas)="seleccionarEmpresasSucursales($event)"></app-selector-empresa>
                        </div>

                        <div class="col-12 col-md-3 form-group">
                            <label>Período Desde:</label>
                            <kendo-datepicker class="form-control" placeholder="Ingresa" (valueChange)="actualizarFechaFin($event)" [disabled]="empresasSucursalSeleccionadas.length === 0" [min]="min" [max]="max" [(value)]="fecIni" (keydown)="filtrarReporteKeydown($event)">
                                <kendo-datepicker-messages today="Hoy">
                                </kendo-datepicker-messages>
                            </kendo-datepicker>
                            <span *ngIf="errorFiltro.fecIni" class="form-error">
                                Debes ingresar Fecha Desde
                            </span>
                        </div>

                        <div class="col-12 col-md-3 form-group">
                            <label>Período Hasta:</label>
                            <kendo-datepicker class="form-control" placeholder="Ingresa" [disabled]="true" [(value)]="fecFin" (keydown)="filtrarReporteKeydown($event)">
                                <kendo-datepicker-messages today="Hoy">
                                </kendo-datepicker-messages>
                            </kendo-datepicker>
                            <span *ngIf="errorFiltro.fecFin" class="form-error">
                                Debes ingresar Fecha Hasta
                            </span>
                        </div>

                        <div class="col-12 col-md-1 form-group mt-1">
                            <a (click)="filtrarReporte()" data-toggle="tooltip" ngbTooltip="Filtrar Resultados" class="btn btn-primary" style="margin-top:20px;">
                                <img src="../assets/img/busca.png" class="icon-md">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container">
            <div class="col-lg-10 col-12 mt-2 mx-auto">
                <div class="row">
                    <div class="col-12 mt-5">
                        <h1>Consolidado de Cartola</h1>
                    </div>
                    <div *ngIf="empresasSucursalSeleccionadas.length > 1" class="col-12 mt-1">
                        <h4 style="text-align: justify;">
                            Usted ha seleccionado más de una Empresa y/o Sucursal, por lo tanto la información contenida en las líneas de Totales de esta Cartola, representan el promedio de la tasa de administración y la suma de aportes e inversiones de todas las Empresas y/o Sucursales
                            escogidas, sin embargo podrá realizar una apertura de cada línea haciendo clic en símbolo <strong>+</strong>.
                        </h4>
                    </div>
                    <div class="col-12">
                        <div *ngIf="permisosModulosAcciones.REPOCART.acciones.includes('CarExpEXC_01') || permisosModulosAcciones.REPOCART.acciones.includes('CarExpPDF_01')" class="dropdown">
                            <button class="btn btn-primary float-right mt-4 dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img src="../assets/img/bajar.png" class="icon-md mr-1"> Exportar
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a *ngIf="permisosModulosAcciones.REPOCART.acciones.includes('CarExpPDF_01')" (click)="pdf.saveAs('Cartola.pdf')" class="dropdown-item">PDF</a>
                            </div>
                            <div class="col-md-2 col-lg-2 form-group float-right" style="margin-top: 20px;">
                                <label>Fecha Informe:</label>
                                <p>{{fechaActual}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mt-3" style="border-radius:15px;border:1px solid #ccc;">
                        <div class="row p-2" style="border-bottom:1px solid #ccc;">
                            <div class="col-md-6">
                                <h4 class="margin-l-30">Aporte Comprometido</h4>
                            </div>
                            <div class="col-md-5 text-right margin-l-30">
                                <h4>${{cartolaCompleta.aporteComprometido | number | miles}}</h4>
                            </div>
                            <div class="col-md-1"></div>
                        </div>
                        <div class="row p-2" style="border-bottom:1px solid #ccc;">
                            <div class="col-md-6">
                                <h4 class="margin-l-30">1% año anterior</h4>
                            </div>
                            <div class="col-md-5 text-right margin-l-30">
                                <h4>${{cartolaCompleta.unoAnterior | number | miles}}</h4>
                            </div>
                            <div class="col-md-1"></div>
                        </div>
                        <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="cartolaCompleta.comision.comisionCollapse = !cartolaCompleta.comision.comisionCollapse">
                            <div *ngIf="cartolaCompleta.comision.comisiones.length > 0" class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="cartolaCompleta.comision.comisionCollapse ? 'Ver más' : 'Ver menos'">
                                <img [src]="cartolaCompleta.comision.comisionCollapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                            </div>
                            <div class="col-md-6" [ngClass]="cartolaCompleta.comision.comisiones.length === 0 ? 'margin-l-30' : ''">
                                <h4>% Comisión</h4>
                            </div>
                            <!-- <div class="col-md-5 text-right">
                                <h4>{{cartolaCompleta.comision.comisionPromedio | number | miles}}%</h4>
                            </div> -->
                            <div class="col-md-6"></div>
                        </div>
                        <div *ngIf="cartolaCompleta.comision.comisiones.length > 0" [ngClass]="cartolaCompleta.comision.comisionCollapse ? 'collapse' : 'show'">
                            <div *ngFor="let comision of cartolaCompleta.comision.comisiones" class="row p-2" style="border-bottom:1px solid #ccc;">
                                <div class="col-md-6 margin-l-30">
                                    <h4 class="margin-l-30">
                                        {{comision.nombreempresa === comision.nombresucursal ? comision.nombreempresa : comision.nombreempresa + ' - Sucursal: ' + comision.nombresucursal}}
                                    </h4>
                                </div>
                                <div class="col-md-5 text-right">
                                    <h4>{{comision.tasa_por | number | miles}}%</h4>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mt-4" style="border-radius:15px;border:1px solid #ccc;">
                        <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="cartolaCompleta.ctaNormalyRepartoCollapse = !cartolaCompleta.ctaNormalyRepartoCollapse">
                            <div class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="cartolaCompleta.ctaNormalyRepartoCollapse ? 'Ver más' : 'Ver menos'">
                                <img [src]="cartolaCompleta.ctaNormalyRepartoCollapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                            </div>
                            <div class="col-md-6">
                                <h2 class="text-bold">Cuenta Normal y Reparto</h2>
                            </div>
                            <div class="col-md-5 text-right">
                                <div class="row">
                                    <div class="col-6 mt-1 text-right">
                                        <h4>Saldo Actual:</h4>
                                    </div>
                                    <div class="col-6">
                                        <h2 class="text-bold">${{cartolaCompleta.ctaNormalyReparto | number | miles}}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="cartolaCompleta.ctaNormalyRepartoCollapse ? 'collapse' : 'show'">
                            <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="cartolaCompleta.aporteImputado.aporteImputadoCollapse = !cartolaCompleta.aporteImputado.aporteImputadoCollapse">
                                <div *ngIf="cartolaCompleta.aporteImputado.aportesImputados.length > 0" class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="cartolaCompleta.aporteImputado.aporteImputadoCollapse ? 'Ver más' : 'Ver menos'">
                                    <img [src]="cartolaCompleta.aporteImputado.aporteImputadoCollapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                                </div>
                                <div class="col-md-6">
                                    <h4 [ngClass]="cartolaCompleta.aporteImputado.aportesImputados.length === 0 ? 'margin-l-30' : ''">
                                        Aporte Imputado
                                    </h4>
                                </div>
                                <div class="col-md-5 text-right" [ngClass]="cartolaCompleta.aporteImputado.aporteImputadoTotal === 0 ? 'margin-l-30' : ''">
                                    <h4>${{cartolaCompleta.aporteImputado.aporteImputadoTotal | number | miles}}</h4>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                            <div *ngIf="cartolaCompleta.aporteImputado.aportesImputados.length > 0" [ngClass]="cartolaCompleta.aporteImputado.aporteImputadoCollapse ? 'collapse' : 'show'">
                                <div *ngFor="let aporte of cartolaCompleta.aporteImputado.aportesImputados" class="row p-2" style="border-bottom:1px solid #ccc;">
                                    <div class="col-md-6 margin-l-30">
                                        <h4 class="margin-l-30">
                                            {{aporte.nombreempresa === aporte.nombresucursal ? aporte.nombreempresa : aporte.nombreempresa + ' - Sucursal: ' + aporte.nombresucursal}}
                                        </h4>
                                    </div>
                                    <div class="col-md-5 text-right">
                                        <h4>${{aporte.aporteimpdisp | number | miles}}</h4>
                                    </div>
                                    <div class="col-md-1"></div>
                                </div>
                            </div>
                            <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="cartolaCompleta.invCtaNormalyRep.invCtaNormalyRepCollapse = !cartolaCompleta.invCtaNormalyRep.invCtaNormalyRepCollapse">
                                <div *ngIf="cartolaCompleta.invCtaNormalyRep.subClienteNormalyRep.length > 0" class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="cartolaCompleta.invCtaNormalyRep.invCtaNormalyRepCollapse ? 'Ver más' : 'Ver menos'">
                                    <img [src]="cartolaCompleta.invCtaNormalyRep.invCtaNormalyRepCollapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                                </div>
                                <div class="col-md-6" [ngClass]="cartolaCompleta.invCtaNormalyRep.subClienteNormalyRep.length === 0 ? 'margin-l-30' : ''">
                                    <h4>Total Inversión en Capacitación</h4>
                                </div>
                                <div class="col-md-5 text-right ">
                                    <h4>${{cartolaCompleta.invCtaNormalyRep.invCtaNormalyRepTotal | number | miles}}</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div *ngIf="cartolaCompleta.invCtaNormalyRep.subClienteNormalyRep.length > 0" class="col-12" [ngClass]="cartolaCompleta.invCtaNormalyRep.invCtaNormalyRepCollapse ? 'collapse' : 'show'" style="background:#efefef;">
                                    <div *ngFor="let inscripcion of cartolaCompleta.invCtaNormalyRep.subClienteNormalyRep">
                                        <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                            <div class="col-md-11 margin-l-30">
                                                <h4 class="margin-l-30">
                                                    {{inscripcion.subCliente}}
                                                </h4>
                                            </div>
                                            <div class="col-md-1"></div>
                                        </div>
                                        <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                            <div class="col-md-6 margin-l-30">
                                                <h4 class="margin-l-30">
                                                    <div class="margin-l-30">
                                                        Sub Capacitación Cuenta Normal
                                                    </div>
                                                </h4>
                                            </div>
                                            <div class="col-md-5 text-right">
                                                <h4>${{inscripcion.inscripciones.cuentaNormal | number | miles}}</h4>
                                            </div>
                                            <div class="col-md-1"></div>
                                        </div>
                                        <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                            <div class="col-md-6 margin-l-30">
                                                <h4 class="margin-l-30">
                                                    <div class="margin-l-30">
                                                        Sub Capacitación Cuenta Reparto
                                                    </div>
                                                </h4>
                                            </div>
                                            <div class="col-md-5 text-right">
                                                <h4>${{inscripcion.inscripciones.cuentaReparto | number | miles}}</h4>
                                            </div>
                                            <div class="col-md-1"></div>
                                        </div>
                                        <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                            <div class="col-md-6 margin-l-30">
                                                <h4 class="margin-l-30">
                                                    <div class="margin-l-30">
                                                        Sub Capacitación EYCCL
                                                    </div>
                                                </h4>
                                            </div>
                                            <div class="col-md-5 text-right">
                                                <h4>${{inscripcion.inscripciones.eyccl | number | miles}}</h4>
                                            </div>
                                            <div class="col-md-1"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalCollapse = !cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalCollapse">
                                <div *ngIf="cartolaCompleta.topeInvVyTNormal.topeInvVyTNormales.length > 0" class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalCollapse ? 'Ver más' : 'Ver menos'">
                                    <img [src]="cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalCollapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                                </div>
                                <div class="col-md-6">
                                    <h4 [ngClass]="cartolaCompleta.topeInvVyTNormal.topeInvVyTNormales.length === 0 ? 'margin-l-30' : ''">
                                        Tope Inversión Viáticos y Traslados
                                    </h4>
                                </div>
                                <div class="col-md-5 text-right" [ngClass]="cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalTotal === 0 ? 'margin-l-30' : ''">
                                    <h4>${{cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalTotal | number | miles}}</h4>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                            <div *ngIf="cartolaCompleta.topeInvVyTNormal.topeInvVyTNormales.length > 0" [ngClass]="cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalCollapse ? 'collapse' : 'show'">
                                <div *ngFor="let tope of cartolaCompleta.topeInvVyTNormal.topeInvVyTNormales" class="row p-2" style="border-bottom:1px solid #ccc;">
                                    <div class="col-md-6 margin-l-30">
                                        <h4 class="margin-l-30">
                                            {{tope.nombreempresa === tope.nombresucursal ? tope.nombreempresa : tope.nombreempresa + ' - Sucursal: ' + tope.nombresucursal}}
                                        </h4>
                                    </div>
                                    <div class="col-md-5 text-right">
                                        <h4>${{tope.tope | number | miles}}</h4>
                                    </div>
                                    <div class="col-md-1"></div>
                                </div>
                            </div>
                            <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="cartolaCompleta.invVyTNormal.invVyTNormalCollapse = !cartolaCompleta.invVyTNormal.invVyTNormalCollapse">
                                <div *ngIf="cartolaCompleta.invVyTNormal.invVyTNormales.length > 0" class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="cartolaCompleta.invVyTNormal.invVyTNormalCollapse ? 'Ver más' : 'Ver menos'">
                                    <img [src]="cartolaCompleta.invVyTNormal.invVyTNormalCollapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                                </div>
                                <div class="col-md-6">
                                    <h4 [ngClass]="cartolaCompleta.invVyTNormal.invVyTNormales.length === 0 ? 'margin-l-30' : ''">
                                        Total Inversión Viáticos y Traslados
                                    </h4>
                                </div>
                                <div class="col-md-5 text-right" [ngClass]="cartolaCompleta.invVyTNormal.invVyTNormales.length === 0 && cartolaCompleta.invVyTNormal.invVyTNormalTotal === 0 ? 'margin-l-30' : ''">
                                    <h4>${{cartolaCompleta.invVyTNormal.invVyTNormalTotal | number | miles}}</h4>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                            <div *ngIf="cartolaCompleta.invVyTNormal.invVyTNormales.length > 0" [ngClass]="cartolaCompleta.invVyTNormal.invVyTNormalCollapse ? 'collapse' : 'show'">
                                <div *ngFor="let invVyT of cartolaCompleta.invVyTNormal.invVyTNormales" class="row p-2" style="border-bottom:1px solid #ccc;">
                                    <div class="col-md-6 margin-l-30">
                                        <h4 class="margin-l-30">
                                            {{invVyT.subCliente}}
                                        </h4>
                                    </div>
                                    <div class="col-md-5 text-right">
                                        <h4>${{invVyT.vyt | number | miles}}</h4>
                                    </div>
                                    <div class="col-md-1"></div>
                                </div>
                            </div>
                            <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="cartolaCompleta.comisionInterm.comisionIntermCollapse = !cartolaCompleta.comisionInterm.comisionIntermCollapse">
                                <div *ngIf="cartolaCompleta.comisionInterm.comisionesIntermediadas.length > 0" class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="cartolaCompleta.comisionInterm.comisionIntermCollapse ? 'Ver más' : 'Ver menos'">
                                    <img [src]="cartolaCompleta.comisionInterm.comisionIntermCollapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                                </div>
                                <div class="col-md-6">
                                    <h4 [ngClass]="cartolaCompleta.comisionInterm.comisionesIntermediadas.length === 0 ? 'margin-l-30' : ''">
                                        Total Comisión sobre Intermediación
                                    </h4>
                                </div>
                                <div class="col-md-5 text-right" [ngClass]="cartolaCompleta.comisionInterm.comisionIntermTotal === 0 ? 'margin-l-30' : ''">
                                    <h4>${{cartolaCompleta.comisionInterm.comisionIntermTotal | number | miles}}</h4>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                            <div *ngIf="cartolaCompleta.comisionInterm.comisionesIntermediadas.length > 0" [ngClass]="cartolaCompleta.comisionInterm.comisionIntermCollapse ? 'collapse' : 'show'">
                                <div *ngFor="let comisionInterm of cartolaCompleta.comisionInterm.comisionesIntermediadas" class="row p-2" style="border-bottom:1px solid #ccc;">
                                    <div class="col-md-6 margin-l-30">
                                        <h4 class="margin-l-30">
                                          {{comisionInterm.subCliente}}
                                        </h4>
                                    </div>
                                    <div class="col-md-5 text-right">
                                        <h4>${{comisionInterm.comision | number | miles}}</h4>
                                    </div>
                                    <div class="col-md-1"></div>
                                </div>
                            </div>
                            <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="cartolaCompleta.comisionNoInterm.comisionNoIntermCollapse = !cartolaCompleta.comisionNoInterm.comisionNoIntermCollapse">
                                <div *ngIf="cartolaCompleta.comisionNoInterm.comisionesNoIntermediadas.length > 0" class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="cartolaCompleta.comisionNoInterm.comisionNoIntermCollapse ? 'Ver más' : 'Ver menos'">
                                    <img [src]="cartolaCompleta.comisionNoInterm.comisionNoIntermCollapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                                </div>
                                <div class="col-md-6">
                                    <h4 [ngClass]="cartolaCompleta.comisionNoInterm.comisionesNoIntermediadas.length === 0 ? 'margin-l-30' : ''">
                                        Total Comisión sobre Saldo Aportes No Invertidos
                                    </h4>
                                </div>
                                <div class="col-md-5 text-right" [ngClass]="cartolaCompleta.comisionNoInterm.comisionNoIntermTotal === 0 ? 'margin-l-30' : ''">
                                    <h4>${{cartolaCompleta.comisionNoInterm.comisionNoIntermTotal | number | miles}}</h4>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                            <div *ngIf="cartolaCompleta.comisionNoInterm.comisionesNoIntermediadas.length > 0" [ngClass]="cartolaCompleta.comisionNoInterm.comisionNoIntermCollapse ? 'collapse' : 'show'">
                                <div *ngFor="let comisionNoInterm of cartolaCompleta.comisionNoInterm.comisionesNoIntermediadas">
                                    <div *ngIf="comisionNoInterm.comision > 0" class="row p-2" style="border-bottom:1px solid #ccc;">
                                        <div class="col-md-6 margin-l-30">
                                            <h4 class="margin-l-30">
                                                {{comisionNoInterm.subCliente}}
                                            </h4>
                                        </div>
                                        <div class="col-md-5 text-right">
                                            <h4>${{comisionNoInterm.comision | number | miles}}</h4>
                                        </div>
                                        <div class="col-md-1"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mt-4" style="border-radius:15px;border:1px solid #ccc;">
                        <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="cartolaCompleta.ctaExcedentesCollapse = !cartolaCompleta.ctaExcedentesCollapse">
                            <div class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="cartolaCompleta.ctaExcedentesCollapse ? 'Ver más' : 'Ver menos'">
                                <img [src]="cartolaCompleta.ctaExcedentesCollapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                            </div>
                            <div class="col-md-6">
                                <h2 class="text-bold">Cuenta Excedentes</h2>
                            </div>
                            <div class="col-md-5 text-right">
                                <div class="row">
                                    <div class="col-6 mt-1 text-right">
                                        <h4>Saldo Actual:</h4>
                                    </div>
                                    <div class="col-6">
                                        <h2 class="text-bold">${{cartolaCompleta.ctaExcedentes | number | miles}}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="cartolaCompleta.ctaExcedentesCollapse ? 'collapse' : 'show'">
                            <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqCollapse = !cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqCollapse">
                                <div *ngIf="cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqs.length > 0" class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqCollapse ? 'Ver más' : 'Ver menos'">
                                    <img [src]="cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqCollapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                                </div>
                                <div class="col-md-6">
                                    <h4 [ngClass]="cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqs.length === 0 ? 'margin-l-30' : ''">
                                        Saldo Excedentes Líquido
                                    </h4>
                                </div>
                                <div class="col-md-5 text-right" [ngClass]="cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqTotal === 0 ? 'margin-l-30' : ''">
                                    <h4>${{cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqTotal | number | miles}}</h4>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                            <div *ngIf="cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqs.length > 0" [ngClass]="cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqCollapse ? 'collapse' : 'show'">
                                <div *ngFor="let saldo of cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqs" class="row p-2" style="border-bottom:1px solid #ccc;">
                                    <div class="col-md-6 margin-l-30">
                                        <h4 class="margin-l-30">
                                            {{saldo.nombreempresa === saldo.nombresucursal ? saldo.nombreempresa : saldo.nombreempresa + ' - Sucursal: ' + saldo.nombresucursal}}
                                        </h4>
                                    </div>
                                    <div class="col-md-5 text-right">
                                        <h4>${{saldo.saldo | number | miles}}</h4>
                                    </div>
                                    <div class="col-md-1"></div>
                                </div>
                            </div>
                            <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="cartolaCompleta.invCtaExcedentes.invCtaExcedentesCollapse = !cartolaCompleta.invCtaExcedentes.invCtaExcedentesCollapse">
                                <div *ngIf="cartolaCompleta.invCtaExcedentes.subClienteExcedentes.length > 0" class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="cartolaCompleta.invCtaExcedentes.invCtaExcedentesCollapse ? 'Ver más' : 'Ver menos'">
                                    <img [src]="cartolaCompleta.invCtaExcedentes.invCtaExcedentesCollapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                                </div>
                                <div class="col-md-6" [ngClass]="cartolaCompleta.invCtaExcedentes.subClienteExcedentes.length === 0 ? 'margin-l-30' : ''">
                                    <h4>Total Inversión en Capacitación</h4>
                                </div>
                                <div class="col-md-5 text-right ">
                                    <h4>${{cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal | number | miles}}</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div *ngIf="cartolaCompleta.invCtaExcedentes.subClienteExcedentes.length > 0" class="col-12" [ngClass]="cartolaCompleta.invCtaExcedentes.invCtaExcedentesCollapse ? 'collapse' : 'show'" style="background:#efefef;">
                                    <div *ngFor="let inscripcion of cartolaCompleta.invCtaExcedentes.subClienteExcedentes">
                                        <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                            <div class="col-md-11 margin-l-30">
                                                <h4 class="margin-l-30">
                                                    {{inscripcion.subCliente}}
                                                </h4>
                                            </div>
                                            <div class="col-md-1"></div>
                                        </div>
                                        <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                            <div class="col-md-6 margin-l-30">
                                                <h4 class="margin-l-30">
                                                    <div class="margin-l-30">
                                                        Sub Capacitación Cuenta Excedentes
                                                    </div>
                                                </h4>
                                            </div>
                                            <div class="col-md-5 text-right">
                                                <h4>${{inscripcion.inscripciones.cuentaNormal | number | miles}}</h4>
                                            </div>
                                            <div class="col-md-1"></div>
                                        </div>
                                        <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                            <div class="col-md-6 margin-l-30">
                                                <h4 class="margin-l-30">
                                                    <div class="margin-l-30">
                                                        Sub Capacitación Cuenta Excedentes de Reparto
                                                    </div>
                                                </h4>
                                            </div>
                                            <div class="col-md-5 text-right">
                                                <h4>${{inscripcion.inscripciones.cuentaReparto | number | miles}}</h4>
                                            </div>
                                            <div class="col-md-1"></div>
                                        </div>
                                        <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                            <div class="col-md-6 margin-l-30">
                                                <h4 class="margin-l-30">
                                                    <div class="margin-l-30">
                                                        Sub Capacitación EYCCL
                                                    </div>
                                                </h4>
                                            </div>
                                            <div class="col-md-5 text-right">
                                                <h4>${{inscripcion.inscripciones.eyccl | number | miles}}</h4>
                                            </div>
                                            <div class="col-md-1"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesCollapse = !cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesCollapse">
                                <div *ngIf="cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentes.length > 0" class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesCollapse ? 'Ver más' : 'Ver menos'">
                                    <img [src]="cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesCollapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                                </div>
                                <div class="col-md-6">
                                    <h4 [ngClass]="cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentes.length === 0 ? 'margin-l-30' : ''">
                                        Tope Inversión Viáticos y Traslados
                                    </h4>
                                </div>
                                <div class="col-md-5 text-right" [ngClass]="cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal === 0 ? 'margin-l-30' : ''">
                                    <h4>${{cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal | number | miles}}</h4>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                            <div *ngIf="cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentes.length > 0" [ngClass]="cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesCollapse ? 'collapse' : 'show'">
                                <div *ngFor="let tope of cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentes" class="row p-2" style="border-bottom:1px solid #ccc;">
                                    <div class="col-md-6 margin-l-30">
                                        <h4 class="margin-l-30">
                                            {{tope.nombreempresa === tope.nombresucursal ? tope.nombreempresa : tope.nombreempresa + ' - Sucursal: ' + tope.nombresucursal}}
                                        </h4>
                                    </div>
                                    <div class="col-md-5 text-right">
                                        <h4>${{tope.tope | number | miles}}</h4>
                                    </div>
                                    <div class="col-md-1"></div>
                                </div>
                            </div>
                            <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="cartolaCompleta.invVyTExcedentes.invVyTExcedentesCollapse = !cartolaCompleta.invVyTExcedentes.invVyTExcedentesCollapse">
                                <div *ngIf="cartolaCompleta.invVyTExcedentes.invVyTExcedentes.length > 0" class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="cartolaCompleta.invVyTExcedentes.invVyTExcedentesCollapse ? 'Ver más' : 'Ver menos'">
                                    <img [src]="cartolaCompleta.invVyTExcedentes.invVyTExcedentesCollapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                                </div>
                                <div class="col-md-6">
                                    <h4 [ngClass]="cartolaCompleta.invVyTExcedentes.invVyTExcedentes.length === 0 ? 'margin-l-30' : ''">
                                        Total Inversión Viáticos y Traslados
                                    </h4>
                                </div>
                                <div class="col-md-5 text-right" [ngClass]="cartolaCompleta.invVyTExcedentes.invVyTExcedentes.length === 0 && cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal === 0 ? 'margin-l-30' : ''">
                                    <h4>${{cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal | number | miles}}</h4>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                            <div *ngIf="cartolaCompleta.invVyTExcedentes.invVyTExcedentes.length > 0" [ngClass]="cartolaCompleta.invVyTExcedentes.invVyTExcedentesCollapse ? 'collapse' : 'show'">
                                <div *ngFor="let invVyT of cartolaCompleta.invVyTExcedentes.invVyTExcedentes" class="row p-2" style="border-bottom:1px solid #ccc;">
                                    <div class="col-md-6 margin-l-30">
                                        <h4 class="margin-l-30">
                                            {{invVyT.subCliente}}
                                        </h4>
                                    </div>
                                    <div class="col-md-5 text-right">
                                        <h4>${{invVyT.vyt | number | miles}}</h4>
                                    </div>
                                    <div class="col-md-1"></div>
                                </div>
                            </div>
                            <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="cartolaCompleta.becasMandato.becasMandatoCollapse = !cartolaCompleta.becasMandato.becasMandatoCollapse">
                                <div *ngIf="cartolaCompleta.becasMandato.becasMandatos.length > 0" class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="cartolaCompleta.becasMandato.becasMandatoCollapse ? 'Ver más' : 'Ver menos'">
                                    <img [src]="cartolaCompleta.becasMandato.becasMandatoCollapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                                </div>
                                <div class="col-md-6">
                                    <h4 [ngClass]="cartolaCompleta.becasMandato.becasMandatos.length === 0 ? 'margin-l-30' : ''">
                                        Becas Mandato
                                    </h4>
                                </div>
                                <div class="col-md-5 text-right" [ngClass]="cartolaCompleta.becasMandato.becasMandatoTotal === 0 ? 'margin-l-30' : ''">
                                    <h4>${{cartolaCompleta.becasMandato.becasMandatoTotal | number | miles}}</h4>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                            <div *ngIf="cartolaCompleta.becasMandato.becasMandatos.length > 0" [ngClass]="cartolaCompleta.becasMandato.becasMandatoCollapse ? 'collapse' : 'show'">
                                <div *ngFor="let becas of cartolaCompleta.becasMandato.becasMandatos" class="row p-2" style="border-bottom:1px solid #ccc;">
                                    <div class="col-md-6 margin-l-30">
                                        <h4 class="margin-l-30">
                                            {{becas.nombreempresa === becas.nombresucursal ? becas.nombreempresa : becas.nombreempresa + ' - Sucursal: ' + becas.nombresucursal}}
                                        </h4>
                                    </div>
                                    <div class="col-md-5 text-right">
                                        <h4>${{becas.valor | number | miles}}</h4>
                                    </div>
                                    <div class="col-md-1"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-4 mb-5 float-right text-right">
                        <a [routerLink]="['/home-reportes']" class="btn btn-primary ml-2" style="margin-right:-15px;" data-toggle="tooltip" ngbTooltip="Volver atrás"><img src="../assets/img/volver.png" class="icon-md mr-1">Volver</a>
                        <!-- <a href="#" class="btn btn-primary"><img src="../assets/img/verr.png" class="icon-md"> Ver Detalles</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div style="position: absolute; left: -1000px; top: 0;">
        <kendo-pdf-export #pdf paperSize="A4" margin="1cm" [scale]="0.53">
            <div style="width: 1000px;margin:0px auto;float:none;">
                <div class="col-12 mt-2 mx-auto">
                    <div class="row">
                        <div class="col-4 form-group float-left" style="margin-top: 20px;">
                            <label>Periodo Desde:</label>
                            <p>{{[fecIni.getDate().toString().length === 1 ? '0' + fecIni.getDate().toString() : fecIni.getDate().toString(), (fecIni.getMonth() + 1).toString().length === 1 ? '0' + (fecIni.getMonth() + 1).toString() : (fecIni.getMonth()
                                + 1).toString(), fecIni.getFullYear()].join('/')}}</p>
                        </div>
                        <div class="col-4 form-group float-left" style="margin-top: 20px;">
                            <label>Periodo Hasta:</label>
                            <p>{{[fecFin.getDate().toString().length === 1 ? '0' + fecFin.getDate().toString() : fecFin.getDate().toString(), (fecFin.getMonth() + 1).toString().length === 1 ? '0' + (fecFin.getMonth() + 1).toString() : (fecFin.getMonth()
                                + 1).toString(), fecFin.getFullYear()].join('/')}}</p>
                        </div>
                        <div class="col-4 form-group float-left" style="margin-top: 20px;">
                            <label>Fecha Informe:</label>
                            <p>{{fechaActual}}</p>
                        </div>
                    </div>
                </div>

                <div class="col-12 mt-2 mx-auto">
                    <div class="row">
                        <div class="col-12">
                            <h1>Consolidado de Cartola</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-3" style="border-radius:15px;border:1px solid #ccc;">
                            <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                <div class="col-md-6">
                                    <h4 class="margin-l-30">Aporte Comprometido</h4>
                                </div>
                                <div class="col-md-5 text-right margin-l-30">
                                    <h4>${{cartolaCompleta.aporteComprometido | number | miles}}</h4>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                            <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                <div class="col-md-6">
                                    <h4 class="margin-l-30">1% año anterior</h4>
                                </div>
                                <div class="col-md-5 text-right margin-l-30">
                                    <h4>${{cartolaCompleta.unoAnterior | number | miles}}</h4>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                            <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                <div class="col-md-6">
                                    <h4 class="margin-l-30">% Comisión</h4>
                                </div>
                                <div class="col-md-5 text-right margin-l-30">
                                    <h4>{{cartolaCompleta.comision.comisionPromedio | number | miles}}%</h4>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                            <div>
                                <div *ngFor="let comision of cartolaCompleta.comision.comisiones" class="row p-2" style="border-bottom:1px solid #ccc;">
                                    <div class="col-md-6 margin-l-30">
                                        <h4 class="margin-l-30">
                                            {{comision.nombreempresa === comision.nombresucursal ? comision.nombreempresa : comision.nombreempresa + ' - Sucursal: ' + comision.nombresucursal}}
                                        </h4>
                                    </div>
                                    <div class="col-md-5 text-right">
                                        <h4>{{comision.valor | number | miles}}%</h4>
                                    </div>
                                    <div class="col-md-1"></div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mt-4" style="border-radius:15px;border:1px solid #ccc;">
                            <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                <div class="col-md-6">
                                    <h2 class="text-bold margin-l-30">Cuenta Normal y Reparto</h2>
                                </div>
                                <div class="col-md-5 text-right margin-l-30">
                                    <div class="row">
                                        <div class="col-6 mt-1 text-right">
                                            <h4>Saldo Actual:</h4>
                                        </div>
                                        <div class="col-6">
                                            <h2 class="text-bold">${{cartolaCompleta.ctaNormalyReparto | number | miles}}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                    <div class="col-md-6">
                                        <h4 class="margin-l-30">
                                            Aporte Imputado
                                        </h4>
                                    </div>
                                    <div class="col-md-5 text-right margin-l-30">
                                        <h4>${{cartolaCompleta.aporteImputado.aporteImputadoTotal | number | miles}}</h4>
                                    </div>
                                    <div class="col-md-1"></div>
                                </div>
                                <div>
                                    <div *ngFor="let aporte of cartolaCompleta.aporteImputado.aportesImputados" class="row p-2" style="border-bottom:1px solid #ccc;">
                                        <div class="col-md-6 margin-l-30">
                                            <h4 class="margin-l-30">
                                                {{aporte.nombreempresa === aporte.nombresucursal ? aporte.nombreempresa : aporte.nombreempresa + ' - Sucursal: ' + aporte.nombresucursal}}
                                            </h4>
                                        </div>
                                        <div class="col-md-5 text-right">
                                            <h4>${{aporte.aporteimpdisp | number | miles}}</h4>
                                        </div>
                                        <div class="col-md-1"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                <div class="col-md-6 margin-l-30">
                                    <h4>Total Inversión en Capacitación</h4>
                                </div>
                                <div class="col-md-5 text-right">
                                    <h4>${{cartolaCompleta.invCtaNormalyRep.invCtaNormalyRepTotal | number | miles}}</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12" style="background:#efefef;">
                                    <div *ngFor="let inscripcion of cartolaCompleta.invCtaNormalyRep.subClienteNormalyRep">
                                        <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                            <div class="col-md-11 margin-l-30">
                                                <h4 class="margin-l-30">
                                                    {{inscripcion.subCliente}}
                                                </h4>
                                            </div>
                                            <div class="col-md-1"></div>
                                        </div>
                                        <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                            <div class="col-md-6 margin-l-30">
                                                <h4 class="margin-l-30">
                                                    <div class="margin-l-30">
                                                        Sub Capacitación Cuenta Normal
                                                    </div>
                                                </h4>
                                            </div>
                                            <div class="col-md-5 text-right">
                                                <h4>${{inscripcion.inscripciones.cuentaNormal | number | miles}}</h4>
                                            </div>
                                            <div class="col-md-1"></div>
                                        </div>
                                        <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                            <div class="col-md-6 margin-l-30">
                                                <h4 class="margin-l-30">
                                                    <div class="margin-l-30">
                                                        Sub Capacitación Cuenta Reparto
                                                    </div>
                                                </h4>
                                            </div>
                                            <div class="col-md-5 text-right">
                                                <h4>${{inscripcion.inscripciones.cuentaReparto | number | miles}}</h4>
                                            </div>
                                            <div class="col-md-1"></div>
                                        </div>
                                        <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                            <div class="col-md-6 margin-l-30">
                                                <h4 class="margin-l-30">
                                                    <div class="margin-l-30">
                                                        Sub Capacitación EYCCL
                                                    </div>
                                                </h4>
                                            </div>
                                            <div class="col-md-5 text-right">
                                                <h4>${{inscripcion.inscripciones.eyccl | number | miles}}</h4>
                                            </div>
                                            <div class="col-md-1"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                <div class="col-md-6">
                                    <h4 class="margin-l-30">
                                        Tope Inversión Viáticos y Traslados
                                    </h4>
                                </div>
                                <div class="col-md-5 text-right margin-l-30">
                                    <h4>${{cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalTotal | number | miles}}</h4>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                            <div>
                                <div *ngFor="let tope of cartolaCompleta.topeInvVyTNormal.topeInvVyTNormales" class="row p-2" style="border-bottom:1px solid #ccc;">
                                    <div class="col-md-6 margin-l-30">
                                        <h4 class="margin-l-30">
                                            {{tope.nombreempresa === tope.nombresucursal ? tope.nombreempresa : tope.nombreempresa + ' - Sucursal: ' + tope.nombresucursal}}
                                        </h4>
                                    </div>
                                    <div class="col-md-5 text-right">
                                        <h4>${{tope.tope | number | miles}}</h4>
                                    </div>
                                    <div class="col-md-1"></div>
                                </div>
                            </div>
                            <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                <div class="col-md-6">
                                    <h4 class="margin-l-30">
                                        Total Inversión Viáticos y Traslados
                                    </h4>
                                </div>
                                <div class="col-md-5 text-right margin-l-30">
                                    <h4>${{cartolaCompleta.invVyTNormal.invVyTNormalTotal | number | miles}}</h4>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                            <div>
                                <div *ngFor="let invVyT of cartolaCompleta.invVyTNormal.invVyTNormales" class="row p-2" style="border-bottom:1px solid #ccc;">
                                    <div class="col-md-6 margin-l-30">
                                        <h4 class="margin-l-30">
                                            {{invVyT.subCliente}}
                                        </h4>
                                    </div>
                                    <div class="col-md-5 text-right">
                                        <h4>${{invVyT.vyt | number | miles}}</h4>
                                    </div>
                                    <div class="col-md-1"></div>
                                </div>
                            </div>
                            <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                <div class="col-md-6">
                                    <h4 class="margin-l-30">
                                        Total Comisión sobre Intermediación
                                    </h4>
                                </div>
                                <div class="col-md-5 text-right margin-l-30">
                                    <h4>${{cartolaCompleta.comisionInterm.comisionIntermTotal | number | miles}}</h4>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                            <div>
                                <div *ngFor="let comisionInterm of cartolaCompleta.comisionInterm.comisionesIntermediadas" class="row p-2" style="border-bottom:1px solid #ccc;">
                                    <div class="col-md-6 margin-l-30">
                                        <h4 class="margin-l-30">
                                            {{comisionInterm.subCliente}}
                                        </h4>
                                    </div>
                                    <div class="col-md-5 text-right">
                                        <h4>${{comisionInterm.comision | number | miles}}</h4>
                                    </div>
                                    <div class="col-md-1"></div>
                                </div>
                            </div>
                            <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                <div class="col-md-6">
                                    <h4 class="margin-l-30">
                                        Total Comisión sobre Saldo Aportes No Invertidos
                                    </h4>
                                </div>
                                <div class="col-md-5 text-right margin-l-30">
                                    <h4>${{cartolaCompleta.comisionNoInterm.comisionNoIntermTotal | number | miles}}</h4>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                            <div>
                                <div *ngFor="let comisionNoInterm of cartolaCompleta.comisionNoInterm.comisionesNoIntermediadas">
                                    <div *ngIf="comisionNoInterm.comision > 0" class="row p-2" style="border-bottom:1px solid #ccc;">
                                        <div class="col-md-6 margin-l-30">
                                            <h4 class="margin-l-30">
                                                {{comisionNoInterm.subCliente}}
                                            </h4>
                                        </div>
                                        <div class="col-md-5 text-right">
                                            <h4>${{comisionNoInterm.comision | number | miles}}</h4>
                                        </div>
                                        <div class="col-md-1"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mt-4" style="border-radius:15px;border:1px solid #ccc;">
                            <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                <div class="col-md-6">
                                    <h2 class="text-bold margin-l-30">Cuenta Excedentes</h2>
                                </div>
                                <div class="col-md-5 text-right margin-l-30">
                                    <div class="row">
                                        <div class="col-6 mt-1 text-right">
                                            <h4>Saldo Actual:</h4>
                                        </div>
                                        <div class="col-6">
                                            <h2 class="text-bold">${{cartolaCompleta.ctaExcedentes | number | miles}}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                    <div class="col-md-6">
                                        <h4 class="margin-l-30">
                                            Saldo Excedentes Líquido
                                        </h4>
                                    </div>
                                    <div class="col-md-5 text-right margin-l-30">
                                        <h4>${{cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqTotal | number | miles}}</h4>
                                    </div>
                                    <div class="col-md-1"></div>
                                </div>
                                <div>
                                    <div *ngFor="let saldo of cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqs" class="row p-2" style="border-bottom:1px solid #ccc;">
                                        <div class="col-md-6 margin-l-30">
                                            <h4 class="margin-l-30">
                                                {{saldo.nombreempresa === saldo.nombresucursal ? saldo.nombreempresa : saldo.nombreempresa + ' - Sucursal: ' + saldo.nombresucursal}}
                                            </h4>
                                        </div>
                                        <div class="col-md-5 text-right">
                                            <h4>${{saldo.saldo | number | miles}}</h4>
                                        </div>
                                        <div class="col-md-1"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                <div class="col-md-6 margin-l-30">
                                    <h4>Total Inversión en Capacitación</h4>
                                </div>
                                <div class="col-md-5 text-right">
                                    <h4>${{cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal | number | miles}}</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12" style="background:#efefef;">
                                    <div *ngFor="let inscripcion of cartolaCompleta.invCtaExcedentes.subClienteExcedentes">
                                        <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                            <div class="col-md-11 margin-l-30">
                                                <h4 class="margin-l-30">
                                                    {{inscripcion.subCliente}}
                                                </h4>
                                            </div>
                                            <div class="col-md-1"></div>
                                        </div>
                                        <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                            <div class="col-md-6 margin-l-30">
                                                <h4 class="margin-l-30">
                                                    <div class="margin-l-30">
                                                        Sub Capacitación Cuenta Excedentes
                                                    </div>
                                                </h4>
                                            </div>
                                            <div class="col-md-5 text-right">
                                                <h4>${{inscripcion.inscripciones.cuentaNormal | number | miles}}</h4>
                                            </div>
                                            <div class="col-md-1"></div>
                                        </div>
                                        <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                            <div class="col-md-6 margin-l-30">
                                                <h4 class="margin-l-30">
                                                    <div class="margin-l-30">
                                                        Sub Capacitación Cuenta Excedentes de Reparto
                                                    </div>
                                                </h4>
                                            </div>
                                            <div class="col-md-5 text-right">
                                                <h4>${{inscripcion.inscripciones.cuentaReparto | number | miles}}</h4>
                                            </div>
                                            <div class="col-md-1"></div>
                                        </div>
                                        <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                            <div class="col-md-6 margin-l-30">
                                                <h4 class="margin-l-30">
                                                    <div class="margin-l-30">
                                                        Sub Capacitación EYCCL
                                                    </div>
                                                </h4>
                                            </div>
                                            <div class="col-md-5 text-right">
                                                <h4>${{inscripcion.inscripciones.eyccl | number | miles}}</h4>
                                            </div>
                                            <div class="col-md-1"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                <div class="col-md-6">
                                    <h4 class="margin-l-30">
                                        Tope Inversión Viáticos y Traslados
                                    </h4>
                                </div>
                                <div class="col-md-5 text-right margin-l-30">
                                    <h4>${{cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal | number | miles}}</h4>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                            <div>
                                <div *ngFor="let tope of cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentes" class="row p-2" style="border-bottom:1px solid #ccc;">
                                    <div class="col-md-6 margin-l-30">
                                        <h4 class="margin-l-30">
                                            {{tope.nombreempresa === tope.nombresucursal ? tope.nombreempresa : tope.nombreempresa + ' - Sucursal: ' + tope.nombresucursal}}
                                        </h4>
                                    </div>
                                    <div class="col-md-5 text-right">
                                        <h4>${{tope.tope | number | miles}}</h4>
                                    </div>
                                    <div class="col-md-1"></div>
                                </div>
                            </div>
                            <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                <div class="col-md-6">
                                    <h4 class="margin-l-30">
                                        Total Inversión Viáticos y Traslados
                                    </h4>
                                </div>
                                <div class="col-md-5 text-right margin-l-30">
                                    <h4>${{cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal | number | miles}}</h4>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                            <div>
                                <div *ngFor="let invVyT of cartolaCompleta.invVyTExcedentes.invVyTExcedentes" class="row p-2" style="border-bottom:1px solid #ccc;">
                                    <div class="col-md-6 margin-l-30">
                                        <h4 class="margin-l-30">
                                            {{invVyT.subCliente}}
                                        </h4>
                                    </div>
                                    <div class="col-md-5 text-right">
                                        <h4>${{invVyT.vyt | number | miles}}</h4>
                                    </div>
                                    <div class="col-md-1"></div>
                                </div>
                            </div>
                            <div class="row p-2" style="border-bottom:1px solid #ccc;">
                                <div class="col-md-6">
                                    <h4 class="margin-l-30">
                                        Becas Mandato
                                    </h4>
                                </div>
                                <div class="col-md-5 text-right margin-l-30">
                                    <h4>${{cartolaCompleta.becasMandato.becasMandatoTotal | number | miles}}</h4>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                            <div>
                                <div *ngFor="let becas of cartolaCompleta.becasMandato.becasMandatos" class="row p-2" style="border-bottom:1px solid #ccc;">
                                    <div class="col-md-6 margin-l-30">
                                        <h4 class="margin-l-30">
                                            {{becas.nombreempresa === becas.nombresucursal ? becas.nombreempresa : becas.nombreempresa + ' - Sucursal: ' + becas.nombresucursal}}
                                        </h4>
                                    </div>
                                    <div class="col-md-5 text-right">
                                        <h4>${{becas.valor | number | miles}}</h4>
                                    </div>
                                    <div class="col-md-1"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </kendo-pdf-export>
    </div>
</main>
