import { Injectable } from '@angular/core';
import { RolService } from 'src/app/services/rol.service';
import { LocalStorageService } from 'angular-web-storage';

@Injectable()
export class AuthService {

    constructor(
        private rolService: RolService,
        private localStorageService: LocalStorageService
    ) {

    }

    cargarAccionesActivas(usuarioId: number): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this.rolService.accionesActivas(usuarioId)
                .subscribe((response) => {
                    this.localStorageService.set('PERMISOSMODULOSACCIONES', response.modulosConPermisos);
                    resolve();
                });
        });
    }

    cargarPermisosCliCom(usuarioId: number, clienteComercialCodigo: number): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this.rolService.obtenerPermisosClientesComercial(usuarioId)
                .subscribe(response => {
                    const permisosCliCom = response.filter(r => {
                        return (
                            r.clienteComercialCodigo === clienteComercialCodigo &&
                            r.activado
                        );
                    });

                    const permisos = [];
                    permisosCliCom.forEach(permisoCliCom => {
                        permisos.push({
                            posicion: 1, // ????
                            data: {
                                clienteComercialCodigo: permisoCliCom.clienteComercialCodigo,
                                clienteComercialNombre: permisoCliCom.clienteComercialNombre,
                                empresaCodigo: permisoCliCom.empresaCodigo,
                                empresaNombre: permisoCliCom.empresaNombre,
                                empresaRut: permisoCliCom.empresaRut,
                                holdingCodigo: permisoCliCom.holdingCodigo,
                                holdingNombre: permisoCliCom.holdingNombre,
                                sucursalCodigo: permisoCliCom.sucursalCodigo,
                                sucursalNombre: permisoCliCom.sucursalNombre,
                                usuarioId: permisoCliCom.usuarioId,
                            }
                        });
                    });
                    this.localStorageService.set('permisos', permisos);
                    resolve();
                });
        });
    }
}
