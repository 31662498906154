import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsolidadoService {

  constructor(private http: HttpClient) {
    console.log('ConsolidadoService: constructor');
  }

  /**
   * Retorna las fechas minimas y maxima de PVs para empresas seleccionadas
   * @param rutsEmpresa Listado de Ruts de Empresa seleccionada
   */
  obtenerFechasPVs(rutsEmpresa: string): Observable<any> {
    const body = JSON.stringify(rutsEmpresa);
    const headers = {'Content-Type': 'application/json'};
    return this.http.post(`${environment.ENDPOINTS.CONSOLIDADO}/obtenerFechasPVs/`, body, { headers });
  }

  /**
   * Retorna el Reporte Consolidado de Cursos y Participantes
   * @param filtrosReporte Filtros Ingresados
   */
  reporteConsolidado(filtrosReporte: any): Observable<any> {
    const body = JSON.stringify(filtrosReporte);
    const headers = {'Content-Type': 'application/json'};
    return this.http.post(`${environment.ENDPOINTS.CONSOLIDADO}/reporteConsolidado/`, body, { headers });
  }
  /**
   * Inicia el proceso de Generación reporte consolidado
   * @param filtrosReporte Filtros Ingresados
   */
  reporteConsolidadoStart(filtrosReporte: any): Observable<any> {
    const body = JSON.stringify(filtrosReporte);
    const headers = {'Content-Type': 'application/json'};
    return this.http.post(`${environment.ENDPOINTS.CONSOLIDADO}/reporteConsolidadoStart/`, body, { headers });
  }

  /**
   * Inicia el proceso de Generación reporte consolidado de cursos
   * @param filtrosReporte Filtros Ingresados
   */
  reporteConsolidadoCursosStart(filtrosReporte: any): Observable<any> {
    const body = JSON.stringify(filtrosReporte);
    const headers = {'Content-Type': 'application/json'};
    return this.http.post(`${environment.ENDPOINTS.CONSOLIDADO}/reporteConsolidadoCursosStart/`, body, { headers });
  }
  /**
   * Obtiene los datos del reporte consolidado de cursos
   * @param filtrosReporte Filtros Ingresados
   */
  reporteConsolidadoCursosSAP(filtrosReporte: any): Observable<any> {
    const body = JSON.stringify(filtrosReporte);
    const headers = {'Content-Type': 'application/json'};
    return this.http.post(`${environment.API_DOMAINS.GCH_API}/consolidado/reporteConsolidadoCursos`, body, { headers });
  }

  /**
   * Inicia el proceso de Generación reporte historial del participante
   * @param filtrosReporte Filtros Ingresados
   */
  reporteHistorialParticipanteStart(filtrosReporte: any): Observable<any> {
      const body = JSON.stringify(filtrosReporte);
      const headers = {'Content-Type': 'application/json'};
      return this.http.post(`${environment.ENDPOINTS.CONSOLIDADO}/reporteHistorialParticipanteStart/`, body, { headers });
  }
}
