<main>
    <div class="container-fluid mb-4 fondo-gris-ok" style="margin-top:-20px;">
        <div class="container">
            <div class="row">
                <div class="col-md-7 col-12 mt-3">
                    <h1>Reporte Consolidado de Cursos y Participantes</h1>
                </div>
                <div class="col-md-5 col-12">
                    <div aria-label="breadcrumb" class="text-right float-right">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href={{urlGestion}}>Home</a></li>
                            <li class="breadcrumb-item"><a [routerLink]="['/home-reportes']">Reportes</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Reporte Consolidado de Cursos y Participantes</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-12 form-group">
                    <div class="row">
                        <div class="col-12" style="margin-bottom: 25px;">
                          <p>Selecciona una o varias Empresas y/o Sucursales y el Período a consultar. Si deseas personalizar el reporte, puedes seleccionar una plantilla guardada o crear una nueva plantilla en "personalización de reporte" y seleccionar la opciones que desees visualizar. Una vez configuradas las opciones, presiona el buscador para realizar el reporte.</p>
                        </div>
                    </div>
                    <div class="row" style="margin-bottom: 20px;">
                        <div class="col-12 col-md-5 col-lg-6">
                            <app-selector-empresa [empresas]="empresas" (seleccionEmpresas)="seleccionarEmpresasSucursales($event)"></app-selector-empresa>
                            <div class="row">
                                <div class="col-12 form-group">
                                    <label>Plantilla personalizada reporte:</label>
                                    <select kendo-drop-down-list #selectPlantillas class="form-control seleccion-plantilla" (change)="seleccionPlantilla($event)">
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 form-group">
                                    <a (click)="openModal(content);" class="link-personalizacion-reporte">Personalización del Reporte</a>
                                </div>

                                <!-- <ng-template #content let-modal> -->
                                <ng-template #content let-c="close" let-d="dismiss">
                                  <div class="modal-body" style="padding: 25px;">
                                    <div class="row">
                                      <div class="col-12 header-title-modal">
                                        <span class="modal-title" style="font-weight: bold; font-size: 18px;">Personalización Reporte</span>
                                        <a class="close" aria-label="Close" (click)="closeModal()">
                                          <img aria-hidden="true" src="../../../assets/img/closex.svg" alt="">
                                        </a>
                                      </div>
                                      <div class="col-12 form-group">
                                        <label>Nombre de la Plantilla personalizada:</label>
                                        <input [(ngModel)]="nombrePlantilla" class="form-control cursor-pointer" placeholder="Escriba el nombre de la plantilla">
                                        <span *ngIf="!nombrePlantillaVacio" class="form-error" style="margin-top: 5px;">
                                          Debes ingresar un nombre para la plantilla.
                                        </span>
                                      </div>
                                      <div class="col-12">
                                        <p>Selecciona o arrastra una o varias opciones disponibles para tu reporte personalizado. Una vez que termines, presiona el
                                          botón agregar. En la columna derecha verás tu selección. Puedes agregar y quitar las opciones.</p>
                                      </div>
                                      <div class="col-12">
                                        <span *ngIf="!opcionesAgregadasVacio" class="form-error" style="margin-top: 5px;">
                                          Debes agregar por lo menos una opcion del reporte.
                                        </span>
                                        <app-dual-list
                                          [sort]="false"
                                          [source]="cabecerasDisponibles"
                                          key="recaId"
                                          display="nombre"
                                          [(destination)]="cabecerasSeleccionadas"
                                          height="200px"
                                        ></app-dual-list>
                                      </div>
                                      <div class="col-12 modal-footer footer-button-modal">
                                        <button type="button" class="btn btn-cancel-modal" (click)="closeModal()">
                                          <img style="margin-right: 10px; height: 20px;" src="../../../assets/img/eliminars.png">
                                          Cancelar
                                        </button>
                                        <button type="button" class="btn btn-primary" (click)="guardarPlantilla()">
                                          <img style="margin-right: 10px; height: 20px;" src="../../../assets/img/ok-dale.png">
                                          Guardar
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </ng-template>

                            </div>
                            <span *ngIf="errorFiltro.empresaSucursal" class="form-error">
                              Debes seleccionar al menos una Empresa o Sucursal.
                            </span>
                        </div>

                        <div class="col-12 col-md-5 col-lg-6">
                            <div class="row">
                                <div class="col-12 col-md-6 col-lg-4 form-group">
                                    <label>Fecha Inicio Desde:</label>
                                    <kendo-datepicker class="form-control contenedor-datepicker" placeholder="Ingresa" [(ngModel)]="fecIni" (valueChange)="configuraFechas()" [disabled]="empresasSucursalSeleccionadas.length === 0 || sinPVs" [min]="min" [max]="max" (keydown)="filtrarReporteKeydown($event)">
                                        <kendo-datepicker-messages today="Hoy">
                                        </kendo-datepicker-messages>
                                    </kendo-datepicker>
                                    <!-- <kendo-combobox [data]="listFecIni" [(ngModel)]="fecIni" (valueChange)="configuraFechas()" [disabled]="empresasSucursalSeleccionadas.length === 0 || sinPVs" [suggest]="true" class="form-control" placeholder="Selecciona el Año" (keydown)="filtrarReporteKeydown($event)">
                                    </kendo-combobox> -->
                                    <span *ngIf="errorFiltro.fecIni" class="form-error">
                                      Debes seleccionar Fecha Desde
                                  </span>
                                </div>

                                <div class="col-12 col-md-6 col-lg-4 form-group">
                                    <label>Fecha Término Hasta:</label>
                                    <kendo-datepicker class="form-control contenedor-datepicker" placeholder="Ingresa" [(ngModel)]="fecFin" (valueChange)="configuraFechas()" [disabled]="empresasSucursalSeleccionadas.length === 0 || sinPVs" [min]="min" [max]="max" [disabled]="true" (keydown)="filtrarReporteKeydown($event)">
                                        <kendo-datepicker-messages today="Hoy">
                                        </kendo-datepicker-messages>
                                    </kendo-datepicker>
                                    <!-- <kendo-combobox [data]="listFecFin" [(ngModel)]="fecFin" (valueChange)="configuraFechas()" [disabled]="empresasSucursalSeleccionadas.length === 0 || sinPVs" [suggest]="true" class="form-control" placeholder="Selecciona el Año" (keydown)="filtrarReporteKeydown($event)">
                                    </kendo-combobox> -->
                                    <span *ngIf="errorFiltro.fecFin" class="form-error">
                                      Debes seleccionar Fecha Hasta
                                  </span>
                                </div>

                                <div class="col-12 col-md-2 form-group mt-1">
                                    <a *ngIf="permisosModulosAcciones.REPCURPART.acciones.includes('REPCURPART_Acceder')" (click)="filtrarReporte()" data-toggle="tooltip" ngbTooltip="Descargar Reporte" class="btn btn-primary" style="margin-top:20px;">
                                        <img src="../assets/img/busca.png" class="icon-md">
                                    </a>
                                </div>

                                <div class="col-12 form-group">
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <span>&nbsp;&nbsp;Mostrar en el Reporte:</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12 form-group">
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input type="checkbox" id="checkAnulados" class="k-checkbox" [(ngModel)]="checkAnulados"/>
                                            <span>&nbsp;&nbsp;Acciones de Capacitación y Participantes anulados.</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12 form-group">
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input type="checkbox" id="checkAnulados" class="k-checkbox" [(ngModel)]="checkCASeparados"/>
                                            <span>&nbsp;&nbsp;Campos Adicionales con Código y Descripción separado en columnas.</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12 form-group">
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input type="checkbox" id="checkAnulados" class="k-checkbox" [(ngModel)]="checkCASoloCC"/>
                                            <span>&nbsp;&nbsp;Campos Adicionales pertenecientes al Cliente Comercial.</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
