import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule, appRoutingProvider } from './app.routing';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeCl from '@angular/common/locales/es-CL';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import '@progress/kendo-angular-intl/locales/es-CL/all';
import { IntlModule } from '@progress/kendo-angular-intl';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { PopupModule } from '@progress/kendo-angular-popup';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';

import {AuthInterceptor} from './interceptors/auth.interceptor';
import {ErrorInterceptor} from './interceptors/error.interceptor';

import { MilesPipe } from './pipes/miles.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { TelefonoPipe } from './pipes/telefono.pipe';
import { NumberPipe } from './pipes/number.pipe';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeReportesComponent } from './components/home-reportes/home-reportes.component';
import { RepAportesImputadosComponent } from './components/rep-aportes-imputados/rep-aportes-imputados.component';
import { RepCartolaComponent } from './components/rep-cartola/rep-cartola.component';
import { RepCartolaSAPComponent } from './components/rep-cartola-sap/rep-cartola-sap.component';
import { RepCartolaSAPEnvioMasivoComponent } from './components/rep-cartola-sap-envio-masivo/rep-cartola-sap-envio-masivo.component';
import { AuthComponent } from './components/auth/auth.component';
import { RepConsolidadoCursosPartComponent } from './components/rep-consolidado-cursos-part/rep-consolidado-cursos-part.component';
import { InactivityTimerComponent } from './components/inactivity-timer/inactivity-timer.component';
import { AuthGuard } from './_guards';
import { TimeoutdialogService, TimeoutdialogComponent } from './services/timeoutdialog.service';
import { TimeoutService } from './services/timeout.service';
import { RepCartolaSociedadComponent } from './components/rep-cartola-sociedad/rep-cartola-sociedad.component';
import { RepConsolidadoCursosComponent } from './components/rep-consolidado-cursos/rep-consolidado-cursos.component';
import { RepHistorialParticipanteComponent } from './components/rep-historial-participante/rep-historial-participante.component';
import { DescargasComponent } from './components/descargas/descargas.component';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { AppDualListComponent } from './components/rep-consolidado-cursos-part/app-dual-list/app-dual-list.component';
import { SelectorEmpresaComponent } from './components/common/selector-empresa/selector-empresa.component';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { LabelModule } from '@progress/kendo-angular-label';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { NavigationModule } from '@progress/kendo-angular-navigation';




registerLocaleData(localeCl);

@NgModule({
    declarations: [
        MilesPipe,
        AppComponent,
        HeaderComponent,
        FooterComponent,
        HomeReportesComponent,
        RepAportesImputadosComponent,
        RepCartolaComponent,
        AuthComponent,
        RepConsolidadoCursosPartComponent,
        TimeoutdialogComponent,
        InactivityTimerComponent,
        RepCartolaSociedadComponent,
        RepConsolidadoCursosComponent,
        RepHistorialParticipanteComponent,
        DescargasComponent,
        RepCartolaSAPComponent,
        RepCartolaSAPEnvioMasivoComponent,
        CustomDatePipe,
        TelefonoPipe,
        NumberPipe,
        AppDualListComponent,
        SelectorEmpresaComponent,
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        NgbModule,
        DropDownsModule,
        BrowserAnimationsModule,
        InputsModule,
        GridModule,
        ExcelModule,
        LayoutModule,
        DateInputsModule,
        IntlModule,
        TreeViewModule,
        PopupModule,
        NgxSpinnerModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-center',
            preventDuplicates: true,
            closeButton: true
        }),
        PDFExportModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.SW }),
        AngularDualListBoxModule,
        ExcelExportModule,
        LabelModule,
        ProgressBarModule,
        NavigationModule,
    ],
    providers: [
        appRoutingProvider, {
            provide: LOCALE_ID,
            useValue: 'es-CL'
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        TimeoutService,
        TimeoutdialogService,
        AuthGuard,
        InactivityTimerComponent,
        CustomDatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
