import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';

// Shared
import { GLOBALS, GlobalService } from '../../shared/globals.shared';
import { environment } from 'src/environments/environment';
import { PermisosCliComLocalStorageModel } from 'src/app/models/permisosCliComLocalStorage';
import { UserDataLocalStorageModel } from 'src/app/models/userDataLocalStorage';
import { Router, NavigationEnd } from '@angular/router';
import { CheckableSettings, TreeItemLookup } from '@progress/kendo-angular-treeview';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export'
import { LocalStorageService } from 'angular-web-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, defer, forkJoin, of } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { EmpresasSucursalModel } from 'src/app/models/empresasSucursal.model';
import { CartolaService } from 'src/app/services/cartola.service';
import { ClienteComercialService } from 'src/app/services/cliente-comercial.service';
import { UserLoginService } from 'src/app/services/user-login.service';
import { EmpresaModel } from 'src/app/models/empresa.model';
import { ConsolidadoService } from 'src/app/services/consolidado.service';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RowArgs, SelectableSettings } from '@progress/kendo-angular-grid';


@Component({
  selector: 'app-rep-cartola-sap',
  templateUrl: './rep-cartola-sap.component.html',
  styleUrls: ['./rep-cartola-sap.component.scss'],
  providers: [GlobalService]
})
export class RepCartolaSAPComponent {
  @ViewChild('pdf', { read: PDFExportComponent }) reportepdf: PDFExportComponent;

  min: Date = null;
  max: Date = null;
  urlGestion: string = environment.url_gestor;

  fechaActual: string  = [
    new Date().getDate().toString().length === 1 ? '0' + new Date().getDate().toString() : new Date().getDate().toString(),
    (new Date().getMonth() + 1).toString().length === 1 ?
      '0' + (new Date().getMonth() + 1).toString() : (new Date().getMonth() + 1).toString(),
    new Date().getFullYear()].join('/');

  empresasSucursales: any[] = [];
  parsedData: any[] = [];

  checkedKeys: any[] = [];
  searchTerm = '';
  nombreCC:string;
  empresasSucursalSeleccionadas: number[] = [];
  empresas: EmpresasSucursalModel[] = [];
  empresasSeleccionadas: string[] = [];
  empresaSeleccionado: EmpresaModel = new EmpresaModel();
  fecIni: Date = null;
  fecFin: Date = null;
  periodo: Number = new Date().getFullYear()
  userData: UserDataLocalStorageModel = {};

  errorFiltro = {
    empresaSucursal: false,
    filtros:false,
    fecIni: false,
    fecFin: false
  };

  cartolaCompleta = {
    // Cabecera
    aporteComprometido: 0,
    unoAnterior: 0,
    topeInvDnc: 0,
    comision: {
      comisionPromedio: 0,
      comisionCollapse: true,
      comisiones: []
    },
    // Aporte
    aportesCollapse: true,
    aportes: {
      aporteImputadoTotal: 0,
      aportesImputadosAnioAnteriorTotal:0,
      aportesImputados: [],
      aportesImputadosAnioAnterior:[],
      aportes:[]
    },
    // Cuenta Normal y Reparto
    ctaNormalyReparto: 0,
    ctaNormalyRepartoDisp:0,
    ctaNormalYRepartoInter:0,
    ctaNormalyRepartoMinimo:0,
    ctaNormalyRepartoCom:0,
    ctaNormalyRepartoCollapse: true,
    aporteImputado: {
      aporteImputadoTotal: 0,
      aporteImputadoCollapse: true,
      aportesImputados: [],
    },
    invCtaNormal: {
      invCtaNormalTotal: 0,
      invCtaNormalyRepCollapse: true,
      subClienteNormalyRep: []
    },
    invCtaNormalReparto: {
      invCtaNormalTotal: 0,
      invCtaNormalyRepCollapse: true,
      subClienteNormalyRep: []
    },
    topeInvVyTNormal: {
      topeInvVyTNormalTotal: 0,
      topeInvVyTNormalCollapse: true,
      topeInvVyTNormales: [],
    },
    invVyTNormal: {
      invVyTNormalTotal: 0,
      invVyTNormalCollapse: true,
      invVyTNormales: [],
    },
    comisionInterm: {
      comisionIntermTotal: 0,
      comisionIntermCollapse: true,
      comisionesIntermediadas: [],
    },
    comisionNoInterm: {
      comisionNoIntermTotal: 0,
      comisionNoIntermCollapse: true,
      comisionesNoIntermediadas: [],
    },
    // Cuenta Excedentes
    ctaExcedentes: 0,
    ctaExcedentesInter:0,
    saldoVyTExcedentesTotal:0,
    ctaExcedentesCollapse: true,
    saldoExcedentesLiq: {
      saldoExcedentesLiqTotal: 0,
      saldoExcedentesLiqCollapse: true,
      saldoExcedentesLiqs: []
    },
    invCtaExcedentes: {
      invCtaExcedentesTotal: 0,
      invCtaExcedentesCollapse: true,
      subClienteExcedentes: []
    },
    invCtaExcedentesReparto: {
      invCtaExcedentesTotal: 0,
      invCtaExcedentesCollapse: true,
      subClienteExcedentes: []
    },
    topeInvVyTExcedentes: {
      topeInvVyTExcedentesTotal: 0,
      topeInvVyTExcedentesCollapse: true,
      topeInvVyTExcedentes: [],
    },
    invVyTExcedentes: {
      invVyTExcedentesTotal: 0,
      invVyTExcedentesCollapse: true,
      invVyTExcedentes: [],
    },
    becasMandato: {
      becasMandatoTotal: 0,
      becasMandatoCollapse: true,
      becasMandatos: [],
    }
  };

  detalleCursos = {
    normal:{
      invCtaNormal:0,
      collapse:true,
      cursos:[],
      totales:{
        cursootic:0,
        viaticootic:0,
        trasladootic:0,
        totalotic:0,
        cursoempresa:0,
        viaticoempresa:0,
        trasladoempresa:0,
        totalempresa:0,
        total:0
      }
    },
    reparto:{
      invCtaRep:0,
      collapse:true,
      cursos:[],
      totales:{
        cursootic:0,
        viaticootic:0,
        trasladootic:0,
        totalotic:0,
        cursoempresa:0,
        viaticoempresa:0,
        trasladoempresa:0,
        totalempresa:0,
        total:0
      }
    },
    excedentes:{
      invCtaExc:0,
      collapse:true,
      cursos:[],
      totales:{
        cursootic:0,
        viaticootic:0,
        trasladootic:0,
        totalotic:0,
        cursoempresa:0,
        viaticoempresa:0,
        trasladoempresa:0,
        totalempresa:0,
        total:0
      }
    },
    excedentesReparto:{
      invCtaExcRep:0,
      collapse:true,
      cursos:[],
      totales:{
        cursootic:0,
        viaticootic:0,
        trasladootic:0,
        totalotic:0,
        cursoempresa:0,
        viaticoempresa:0,
        trasladoempresa:0,
        totalempresa:0,
        total:0
      }
    },
  }

  destinatario = {
    nombre:'',
    mail:''
  }

  contacto = {
    nombre:'',
    mail:'',
    tipo:'',
    telefono:''
  }

  permisosModulosAcciones: any = {};
  permisos: Array<PermisosCliComLocalStorageModel>;

  //Exportacion PDF
  seccionesCartola = [
    { nombre: 'Cartola Cuenta Corriente', codigo: 1, valor: 'Cuenta Corriente' },
    { nombre: 'Detalle de aportes', codigo: 2, valor: 'Aportes' },
    { nombre: 'Detalle de cursos contratados', codigo: 3, valor: 'Cursos' },
  ];

  filtrosSeccionesCartola: { nombre:string, codigo:number, valor:string }[] = [];

  selectableSettings: SelectableSettings = {
    checkboxOnly: true,
  };

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private globalService: GlobalService,
    private clienteComercialService: ClienteComercialService,
    private cartolaService: CartolaService,
    private consolidadoService:ConsolidadoService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private userLoginService: UserLoginService) {
    console.log('RepCartolaComponent: constructor');
  }

  ngOnInit() {
    console.log('RepCartolaComponent: OnInit');

    this.userLoginService.isAuthenticated(this);
    this.userData =  this.localStorageService.get('userData');
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });

        this.permisosModulosAcciones = this.localStorageService.get('PERMISOSMODULOSACCIONES') || {};
        this.permisos = this.localStorageService.get('permisos');

        if (this.permisosModulosAcciones.REPOCARTSAP) {
          this.initListadoEmpresas();
          this.configuraFechas();
        } else {
          this.toastr.error(`No estás autorizado para ingresar a esta página`, GLOBALS.TOASTER.TITLE_ERROR);
          this.router.navigate(['/']);
        }

    }
  }

  /**
   * Configura las fechas del formulario
   */
  configuraFechas() {
    if (this.empresasSucursalSeleccionadas.length === 0) {
      this.min = null;
      this.max = null;
      this.fecIni = null;
      this.fecFin = null;
    }

    if (this.min === null && this.max === null) {
      const fechaActual = new Date();

      this.fecIni = new Date(fechaActual.getFullYear(), 0, 1);
      this.fecFin = new Date(fechaActual.getFullYear(), 11, 31);
      this.min = new Date(2006, 0, 1);
      this.max = this.fecFin;
    }
  }

  /**
   * Configura la fecha de Fin del Calendario
   */
  actualizarFechaFin(fecha: any) {
    if (fecha !== null && fecha !== undefined) {
      this.fecFin = new Date(fecha.getFullYear(), 11, 31);
    }
  }

  /**
   * Inicializa el listado de Empresas
   */
  initListadoEmpresas() {
    this.spinner.show();

    const sucursalesAutorizadas = this.permisos.map((item) => item.data.sucursalCodigo).join(',');

    if (sucursalesAutorizadas) {
      this.clienteComercialService.listarEmpresasSucursales(sucursalesAutorizadas)
        .subscribe(async (reporte) => {


            if (reporte.rows.length > 0) {
              this.empresas = reporte.rows.map((empresa) => new EmpresasSucursalModel(empresa));

              let index = 1;
              let codigoEmpresa: number;
              let rama: any;
              for (const empresa of this.empresas) {
                if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa === empresa.codigoSucursal) {
                  if (index !== 1) {
                    this.empresasSucursales.push(rama);
                  }

                  codigoEmpresa = empresa.codigoEmpresa;
                  rama = {
                    text: empresa.rutRazonSocial,
                    value: empresa.codigoSucursal,
                    items: []
                  };
                } else if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa !== empresa.codigoSucursal) {
                  if (index !== 1) {
                    this.empresasSucursales.push(rama);
                  }

                  codigoEmpresa = empresa.codigoEmpresa;
                  rama = {
                    text: empresa.rutRazonSocial,
                    items: [{
                      text: empresa.sucursal,
                      value: empresa.codigoSucursal
                    }]
                  };
                } else {
                  rama.items.push({
                    text: empresa.sucursal,
                    value: empresa.codigoSucursal
                  });
                }

                if (index === this.empresas.length) {
                  this.empresasSucursales.push(rama);
                }

                index++;
              }

              this.parsedData = this.empresasSucursales;
            } else {
              this.toastr.warning(`No hay información de Empresas / Sucursales para Reportes`, `Advertencia`);
            }

            this.spinner.hide();
          }, error => {
            console.log(error);
            this.spinner.hide();
            this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
          }
        );
    } else {
      this.toastr.warning(`No hay Empresas / Sucursales en Cliente Comercial`, `Advertencia`);
      this.spinner.hide();
    }

  }

  /**
   * Crea las Propiedades del TreeView
   */
  public get checkableSettings(): CheckableSettings {
    return {
        checkParents: true,
        mode: 'multiple',
        checkOnClick: false
    };
  }

  /**
   * Trae los hijos del árbol
   */
  public children = (dataItem: any): Observable<any[]> => of(dataItem.items);

  /**
   * Valida si tiene hijos el árbol
   */
  public hasChildren = (dataItem: any): boolean => !!dataItem.items;

  /**
   * Evento al presionar una tecla en la búsqueda
   * @param value Valor Ingresado
   */
  public onkeyup(value: string): void {
    this.parsedData = this.search(this.empresasSucursales, value);
  }

  /**
   * Búsqueda
   * @param items Items
   * @param term Término (Palabra)
   */
  public search(items: any[], term: string): any[] {
    return items.reduce((acc, item) => {
      if (this.contains(item.text, term)) {
        acc.push(item);
      } else if (item.items && item.items.length > 0) {
        const newItems = this.search(item.items, term);

        if (newItems.length > 0) {
          acc.push({ text: item.text, items: newItems });
        }
      }

      return acc;
    }, []);
  }

  /**
   * Compara el Texto y el Término ingresado
   * @param text Texto
   * @param term Término (Palabra)
   */
  public contains(text: string, term: string): boolean {
    return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
  }

  /**
   * Filtra el Reporte
   */
  filtrarReporte() {
    this.errorFiltro = {
      empresaSucursal: false,
      filtros:false,
      fecIni: false,
      fecFin: false
    };

    if (this.empresasSucursalSeleccionadas.length === 0) {
      this.errorFiltro.empresaSucursal = true;
      return;
    } else {
      if (this.fecIni === null) {
        this.errorFiltro.fecIni = true;
      }
      if (this.fecFin === null) {
        this.errorFiltro.fecFin = true;
      }

      if (this.fecIni === null || this.fecFin === null) {
        return;
      }
    }

    const fechaIni: string  = [
      this.fecIni.getFullYear(),
      (this.fecIni.getMonth() + 1).toString().length === 1 ?
        '0' + (this.fecIni.getMonth() + 1).toString() : (this.fecIni.getMonth() + 1).toString(),
      this.fecIni.getDate().toString().length === 1 ? '0' + this.fecIni.getDate().toString() : this.fecIni.getDate().toString()].join('-');

    const fechaFin: string  = [
      this.fecFin.getFullYear(),
      (this.fecFin.getMonth() + 1).toString().length === 1 ?
        '0' + (this.fecFin.getMonth() + 1).toString() : (this.fecFin.getMonth() + 1).toString(),
      this.fecFin.getDate().toString().length === 1 ? '0' + this.fecFin.getDate().toString() : this.fecFin.getDate().toString()].join('-');

      this.periodo = this.fecIni.getFullYear();

      this.nombreCC = this.userData.clienteComercialSeleccionado.nombre;
      const empresa = this.empresas.find(x => this.empresasSucursalSeleccionadas.filter((sucursal) => sucursal  == x.codigoSucursal).length > 0);
    const filtrosReporte = {
      cliComecial:this.nombreCC,
      empresaSucursal: this.empresasSucursalSeleccionadas.join(','),
      fecIni: fechaIni,
      fecFin: fechaFin
    };

    defer(() => this.spinner.show())
      .pipe(
        switchMap(() => forkJoin([
          this.clienteComercialService.obtenerEmpresa(empresa.codigoEmpresa),
          this.cartolaService.reporteCartola(filtrosReporte),
          this.consolidadoService.reporteConsolidadoCursosSAP(filtrosReporte),
          this.cartolaService.obtenerDestinatarioCartola(empresa.codigoEmpresa),
          this.clienteComercialService.obtenerContacto(empresa.codigoEmpresa.toString()),
        ])),
        finalize(() => this.spinner.hide())
      )
        .subscribe(([info,reporte,cursos,destinatario,contacto]) => {

      if(info.length > 0){
        this.empresaSeleccionado = new EmpresaModel(info[0]);
      }

      if(contacto.length > 0){
        this.contacto = contacto.find(x => x.tipo.toString() === 'Ejecutivo Consultor');
      }
      
      if(destinatario.length > 0){
        this.destinatario = destinatario[0];
      }

      this.detalleCursos.normal = {
        collapse:true,
        cursos:cursos.normal,
        invCtaNormal:cursos.normal.reduce((acc: number,curr) => { 
          acc += curr.costocursootic + curr.costoviaticootic + curr.costotrasladootic;
          return acc;
        },0),
        totales:this.calcularTotal(cursos.normal)
      };

      this.detalleCursos.reparto = {
        collapse:true,
        cursos:cursos.reparto,
        invCtaRep:cursos.reparto.reduce((acc: number,curr) => { 
          acc += curr.costocursootic + curr.costoviaticootic + curr.costotrasladootic;
          return acc;
        },0),
        totales:this.calcularTotal(cursos.reparto)
      };

      this.detalleCursos.excedentes = {
        collapse:true,
        cursos:cursos.excedentes,
        invCtaExc:cursos.excedentes.reduce((acc: number,curr) => { 
          acc += curr.costocursootic + curr.costoviaticootic + curr.costotrasladootic;
          return acc;
        },0),
        totales:this.calcularTotal(cursos.excedentes)
      };

      this.detalleCursos.excedentesReparto = {
        collapse:true,
        cursos:cursos.excedentesReparto,
        invCtaExcRep:cursos.excedentesReparto.reduce((acc: number,curr) => { 
          acc += curr.costocursootic + curr.costoviaticootic + curr.costotrasladootic;
          return acc;
        },0),
        totales:this.calcularTotal(cursos.excedentesReparto)
      };


      // Aportes
      this.cartolaCompleta.aportes.aportes = [];
      if(reporte.aportes.length > 0){
        this.cartolaCompleta.aportes.aportes = reporte.aportes;
      }

      // Aporte Comprometido
      this.cartolaCompleta.aporteComprometido = 0;
      if (reporte.aporteComprometido.length > 0) {
        for (const obj of reporte.aporteComprometido) {
          this.cartolaCompleta.aporteComprometido = this.cartolaCompleta.aporteComprometido + obj.valor;
        }
      }

      // 1% año anterior
      this.cartolaCompleta.unoAnterior = 0;
      if (reporte.datosCierre.rows.length > 0) {
        this.cartolaCompleta.unoAnterior = parseInt(reporte.datosCierre.rows[0].klimk,10);
      }

      // Comision
      this.cartolaCompleta.comision.comisionCollapse = true;
      this.cartolaCompleta.comision.comisiones = [];
      this.cartolaCompleta.comision.comisionPromedio = 0;

      const comisionEmpresa = reporte.comision.find((emp:any) => emp.codigo == this.empresaSeleccionado.Codigo);
      if(comisionEmpresa){
        this.cartolaCompleta.comision.comisionPromedio = parseFloat(comisionEmpresa.tasa);
      }

      if (reporte.datosCierre.rows.length > 0) {
        for (const obj of reporte.datosCierre.rows) {
          this.cartolaCompleta.comision.comisiones.push(obj);
        }
      }

      // Aporte Imputado
      this.cartolaCompleta.aporteImputado.aporteImputadoCollapse = true;
      this.cartolaCompleta.aporteImputado.aportesImputados = [];
      this.cartolaCompleta.aporteImputado.aporteImputadoTotal = 0;
      if (reporte.aportes.length > 0) {
        for (const obj of reporte.aporteImputado) {
          this.cartolaCompleta.aporteImputado.aporteImputadoTotal =
          this.cartolaCompleta.aporteImputado.aporteImputadoTotal + parseInt(obj.aporteimpdisp, 10);
          this.cartolaCompleta.aporteImputado.aportesImputados.push(obj);
        }
      }
      // Total comisión por administración
      if(reporte.aportes.length > 0 ){
        const comision = reporte.comision.find((x) => x.codigo === this.empresaSeleccionado.Codigo).tasa;
        this.cartolaCompleta.ctaNormalyRepartoCom = Math.round((this.cartolaCompleta.aporteImputado.aporteImputadoTotal * (parseFloat(comision) / 100)));
      }

      // Total Inversión en Capacitación (Normal y Reparto)
      this.cartolaCompleta.invCtaNormal.invCtaNormalyRepCollapse = true;
      this.cartolaCompleta.invCtaNormal.subClienteNormalyRep = [];
      this.cartolaCompleta.invCtaNormal.invCtaNormalTotal = 0;
      this.cartolaCompleta.invCtaNormalReparto.invCtaNormalyRepCollapse = true;
      this.cartolaCompleta.invCtaNormalReparto.subClienteNormalyRep = [];
      this.cartolaCompleta.invCtaNormalReparto.invCtaNormalTotal = 0;

      if (this.detalleCursos.normal.cursos.concat(this.detalleCursos.reparto.cursos).length > 0) {
        for (const obj of this.detalleCursos.normal.cursos) {
          this.cartolaCompleta.invCtaNormal.invCtaNormalTotal =
            this.cartolaCompleta.invCtaNormal.invCtaNormalTotal +
            parseInt(obj.costocursootic, 10)

          }
        for(const obj of this.detalleCursos.reparto.cursos){
          this.cartolaCompleta.invCtaNormalReparto.invCtaNormalTotal =
            this.cartolaCompleta.invCtaNormalReparto.invCtaNormalTotal +
            parseInt(obj.costocursootic, 10)
        }
      }

      // Tope Inversión Viáticos y Traslados (Normal y Reparto)
      this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalCollapse = true;
      this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormales = [];
      this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalTotal = 0;
        const datosCierreAnterior = reporte.datosCierreAnterior.rows.filter((obj) => obj.cta_exc == 1000);
        if (datosCierreAnterior.length > 0) {
          for (const obj of datosCierreAnterior) {
            //Tope VyT es el 10% del 1% de los aportes del año anterior
            const topeNormal = Math.round(parseInt(obj.klimk, 10) * 0.1);
            this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormales.push({
              nombreempresa: obj.nombreempresa,
              nombresucursal: obj.nombresucursal,
              tope: topeNormal
            });

            this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalTotal =
              this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalTotal + topeNormal;
          }
        }

      // Total Inversión Viáticos y Traslados (Normal y Reparto)
      this.cartolaCompleta.invVyTNormal.invVyTNormalCollapse = true;
      this.cartolaCompleta.invVyTNormal.invVyTNormales = [];
      this.cartolaCompleta.invVyTNormal.invVyTNormalTotal = 0;
      if (reporte.subClienteInscripcionesNormalYReparto.length > 0) {
        for (const obj of reporte.subClienteInscripcionesNormalYReparto) {
          this.cartolaCompleta.invVyTNormal.invVyTNormales.push({
            sucursal: obj.sucursal,
            subCliente: obj.subCliente,
            vyt: obj.inscripciones.vyt
          });

          this.cartolaCompleta.invVyTNormal.invVyTNormalTotal =
            this.cartolaCompleta.invVyTNormal.invVyTNormalTotal + parseInt(obj.inscripciones.vyt, 10);
        }
      }

      // Total Comisión sobre Intermediación (Normal y Reparto)
      this.cartolaCompleta.comisionInterm.comisionIntermCollapse = true;
      this.cartolaCompleta.comisionInterm.comisionesIntermediadas = [];
      this.cartolaCompleta.comisionInterm.comisionIntermTotal = 0;

      if (reporte.subClienteInscripcionesNormalYReparto.length > 0) {
        for (const obj of reporte.subClienteInscripcionesNormalYReparto) {
          const comision = parseInt(obj.inscripciones.comision, 10);

          this.cartolaCompleta.comisionInterm.comisionesIntermediadas.push({
            sucursal: obj.sucursal,
            subCliente: obj.subCliente,
            comision
          });

          this.cartolaCompleta.comisionInterm.comisionIntermTotal =
            this.cartolaCompleta.comisionInterm.comisionIntermTotal + comision;
        }
      }

      // Total Comisión sobre Saldos no Intevertidos (Normal y Reparto)
      this.cartolaCompleta.comisionNoInterm.comisionNoIntermCollapse = true;
      this.cartolaCompleta.comisionNoInterm.comisionesNoIntermediadas = [];
      this.cartolaCompleta.comisionNoInterm.comisionNoIntermTotal = 0;
      if (reporte.subClienteInscripcionesNormalYReparto.length > 0) {
        for (const obj of reporte.subClienteInscripcionesNormalYReparto) {
          //const tasa  = obj.tasa_por ? parseInt(obj.tasa_por, 10) : 0;
          let comision = 0;
          if(this.cartolaCompleta.comision.comisiones.length > 0){
            const reporte = this.cartolaCompleta.comision.comisiones.find(x => x.cod_sucursal === obj.sucursal);
            if(reporte) comision = reporte.tasa_por
          }
          
          const aporte = this.cartolaCompleta.aporteImputado.aportesImputados.find(x => x.sucursal === obj.sucursal);
          const invVyt = this.cartolaCompleta.invVyTNormal.invVyTNormales.find(x => x.sucursal === obj.sucursal);
          const comisionInv = this.cartolaCompleta.comisionInterm.comisionesIntermediadas.find(x => x.sucursal === obj.sucursal);

          const comisionNoInterm =
            Math.round(
            ((aporte !== undefined ? parseInt(aporte.aporteimpdisp, 10) : 0) -
            (parseInt(obj.inscripciones.cuentaNormal, 10) +
            parseInt(obj.inscripciones.cuentaReparto, 10) +
            parseInt(obj.inscripciones.eyccl, 10)) -
            (invVyt !== undefined ? parseInt(invVyt.vyt, 10) : 0) -
            (comisionInv !== undefined ? parseInt(comisionInv.comision, 10) : 0)) *
            (comision !== undefined ? comision / 100 : 0));

          if (comisionNoInterm > 0) {
            this.cartolaCompleta.comisionNoInterm.comisionesNoIntermediadas.push({
              subCliente: obj.subCliente,
              comision: comisionNoInterm
            });

            this.cartolaCompleta.comisionNoInterm.comisionNoIntermTotal =
              this.cartolaCompleta.comisionNoInterm.comisionNoIntermTotal +
              (comisionNoInterm > 0 ? comisionNoInterm : 0);
          }
        }
      }

      // Saldo disponible intermediación

      this.cartolaCompleta.ctaNormalyRepartoDisp =
      this.cartolaCompleta.aporteImputado.aporteImputadoTotal -
      this.cartolaCompleta.ctaNormalyRepartoCom -
      this.cartolaCompleta.invVyTNormal.invVyTNormalTotal;

      this.cartolaCompleta.ctaNormalyRepartoCollapse = true;
      // Total intermediado
      this.cartolaCompleta.ctaNormalYRepartoInter =
        this.cartolaCompleta.invCtaNormal.invCtaNormalTotal +
        this.cartolaCompleta.invCtaNormalReparto.invCtaNormalTotal;
      
        // Saldo Disponible
      this.cartolaCompleta.ctaNormalyReparto =
        this.cartolaCompleta.ctaNormalyRepartoDisp -
        this.cartolaCompleta.ctaNormalYRepartoInter;

      // Monto minimo para aporte siguiente año
      this.cartolaCompleta.ctaNormalyRepartoMinimo = 
        Math.round(this.cartolaCompleta.ctaNormalyReparto / (1 - (this.cartolaCompleta.comision.comisionPromedio / 100)));

      // Saldo Excedentes Líquido (Excedentes)
      this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqCollapse = true;
      this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqs = [];
      this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqTotal = 0;
      if (reporte.datosCierre.rows.length > 0) {
        for (const obj of reporte.datosCierre.rows) {
          /*const comisionAnterior = reporte.comisionAnioAnterior.find(x => x.sucursal === obj.sucursal);
          const inversionAnterior = reporte.inscripcionesAnioAnterior.find(x => x.sucursal === obj.sucursal);
          const saldoExcedentes =
            parseInt(obj.aporteimpdisp, 10) -
            (inversionAnterior !== undefined ? parseInt(inversionAnterior.costootic, 10) : 0) -
            (Math.round((inversionAnterior !== undefined ? parseInt(inversionAnterior.costootic, 10) : 0) *
            (comisionAnterior !== undefined ? parseInt(comisionAnterior.valor, 10) / 100 : 0)) +
            (inversionAnterior !== undefined ? parseInt(inversionAnterior.costovyt, 10) : 0));*/

          const saldoExcedentes = parseInt(obj.exc_liqu, 10);

          this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqs.push({
            nombreempresa: obj.nombreempresa,
            nombresucursal: obj.nombresucursal,
            saldo: saldoExcedentes
          });

          this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqTotal =
            this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqTotal + saldoExcedentes;
        }
      }

      // Total Inversión en Capacitación (Excedentes)
      this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesCollapse = true;
      this.cartolaCompleta.invCtaExcedentes.subClienteExcedentes = [];
      this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal = 0;
      this.cartolaCompleta.invCtaExcedentesReparto.invCtaExcedentesTotal = 0;
      if (this.detalleCursos.excedentes.cursos.concat(this.detalleCursos.excedentesReparto.cursos).length > 0) {
        for (const obj of this.detalleCursos.excedentes.cursos) {
          this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal =
            this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal +
            parseInt(obj.costocursootic, 10)

          }
        for(const obj of this.detalleCursos.excedentesReparto.cursos){
          this.cartolaCompleta.invCtaExcedentesReparto.invCtaExcedentesTotal =
            this.cartolaCompleta.invCtaExcedentesReparto.invCtaExcedentesTotal +
            parseInt(obj.costocursootic, 10)
        }
      }

      // Tope Inversión Viáticos y Traslados (Excedentes)
      this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesCollapse = true;
      this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentes = [];
      this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal = 0;

      if (reporte.aporteImputadoAnioAnterior.length > 0) {
        for (const obj of reporte.aporteImputadoAnioAnterior) {
          /*const inversionVyT = reporte.inscripcionesAnioAnterior.find(x => x.sucursal === obj.sucursal);
          const topeExcedentes =
            Math.round(parseInt(obj.aporteimpdisp, 10) * 0.1) - (inversionVyT !== undefined ? inversionVyT.costovyt : 0);*/

          const datosCierreAnterior = reporte.datosCierreAnterior.rows.find(x => x.cod_sucursal === obj.sucursal);
          //Inicializar si es que no hay datos
          let vt_costo = '0';
          let via_tras = '0';

          if(datosCierreAnterior) {
              vt_costo = datosCierreAnterior.vt_costo;
              via_tras = datosCierreAnterior.via_tras;
              this.cartolaCompleta.aportes.aportesImputadosAnioAnteriorTotal += parseInt(obj.aporteImpDisp,10);
              this.cartolaCompleta.aportes.aportesImputadosAnioAnterior.push(datosCierreAnterior)
          }
          const topeExcedentes = Math.round((parseInt(obj.aporteimpdisp, 10) * 0.1)
                                 - parseInt(vt_costo, 10)
                                 - parseInt(via_tras, 10));

          this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentes.push({
            nombreempresa: obj.nombreempresa,
            nombresucursal: obj.nombresucursal,
            tope: topeExcedentes
          });

          this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal =
            this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal + topeExcedentes;
        }
      }
      // Total Inversión Viáticos y Traslados (Excedentes)
      this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesCollapse = true;
      this.cartolaCompleta.invVyTExcedentes.invVyTExcedentes = [];
      this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal = 0;
      if (reporte.subClienteInscripcionesExcedentes.length > 0) {
        for (const obj of reporte.subClienteInscripcionesExcedentes) {
          this.cartolaCompleta.invVyTExcedentes.invVyTExcedentes.push({
            sucursal: obj.sucursal,
            subCliente: obj.subCliente,
            vyt: obj.inscripciones.vyt
          });

          this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal =
            this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal + parseInt(obj.inscripciones.vyt, 10);
        }
      }

      //Saldo actual Viáticos y Traslados (Excedentes)
      this.cartolaCompleta.saldoVyTExcedentesTotal = 
        this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal - this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal;

      // Becas Mandato
      this.cartolaCompleta.becasMandato.becasMandatoCollapse = true;
      this.cartolaCompleta.becasMandato.becasMandatos = [];
      this.cartolaCompleta.becasMandato.becasMandatoTotal = 0;
      if (reporte.becasMandato.length > 0) {
        for (const obj of reporte.becasMandato) {
          this.cartolaCompleta.becasMandato.becasMandatos.push(obj);

          this.cartolaCompleta.becasMandato.becasMandatoTotal = this.cartolaCompleta.becasMandato.becasMandatoTotal + obj.valor;
        }
      }

      this.cartolaCompleta.ctaExcedentesCollapse = true;
      this.cartolaCompleta.ctaExcedentesInter =
      this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal + //(**)
      this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal +
      this.cartolaCompleta.becasMandato.becasMandatoTotal;

      this.cartolaCompleta.ctaExcedentes =
      this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqTotal - //(**)
      this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal - //(**)
      this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal -
      this.cartolaCompleta.becasMandato.becasMandatoTotal;

      //Validacion Tope V&T Excedentes no puede ser mayor al saldo Excedentes

      this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal =
        this.cartolaCompleta.ctaExcedentes > this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal ? this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal : this.cartolaCompleta.ctaExcedentes;

      this.cartolaCompleta.saldoVyTExcedentesTotal = 
        this.cartolaCompleta.ctaExcedentes > this.cartolaCompleta.saldoVyTExcedentesTotal ? this.cartolaCompleta.saldoVyTExcedentesTotal : this.cartolaCompleta.ctaExcedentes;

      console.log('cartola',this.cartolaCompleta);

      console.log('detalle cursos',this.detalleCursos);

      if (reporte.clienteCerrado.toString() === 'No') {
        this.toastr.warning('Cliente no está cerrado, los valores pueden variar', GLOBALS.TOASTER.TITLE_INFO);
      }
    },(error:any) =>{
      console.log(error);
      this.spinner.hide();
      this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
    })
  }

  /**
   * Filtra Reporte al presionar tecla Enter
   * @param event Evento
   */
  filtrarReporteKeydown(event) {
    if (event.key === 'Enter') {
      this.filtrarReporte();
    }
  }

  openModal(refModal){
    const modalref = this.modalService.open(refModal);
    modalref.result.then((result) => {
      if(result){
        this.iniciarEnvioMasivo();
      }
    });
  }

  seleccionarEmpresasSucursales(sucursales:number[]){
    this.empresasSucursalSeleccionadas = sucursales;
  }

  filtrarEmpresas(){
    const empresas = this.empresas.filter(x => this.empresasSucursalSeleccionadas.filter((sucursal:any) => sucursal  == x.codigoSucursal).length > 0);

    this.empresasSeleccionadas = Array.from<string>(new Set<any>(empresas.map((emp) => emp.razonSocial)));
  }

  calcularTotal(cursos:any[]){
    let totales = {
      cursootic:0,
      viaticootic:0,
      trasladootic:0,
      totalotic:0,
      cursoempresa:0,
      viaticoempresa:0,
      trasladoempresa:0,
      totalempresa:0,
      total:0
    };

    for(const curso of cursos){
      totales.cursootic += curso.costocursootic;
      totales.viaticootic += curso.costoviaticootic;
      totales.trasladootic += curso.costotrasladootic;
      totales.totalotic += (curso.costocursootic + curso.costoviaticootic + curso.costotrasladootic);
      totales.cursoempresa += curso.costocursoempresa;
      totales.viaticootic += curso.costoviaticoempresa;
      totales.trasladoempresa += curso.costotrasladoempresa;
      totales.totalempresa += (curso.costocursoempresa + curso.costoviaticoempresa + curso.costotrasladoempresa);

      totales.total += (curso.costootic + curso.costoempresa);
    }

    return totales;
  }


  iniciarEnvioMasivo(){
    const filtros = {
      cliComercial:this.empresasSucursalSeleccionadas.length > 0 ? this.userData.clienteComercialSeleccionado :'TODOS',
      empresas:this.empresasSucursalSeleccionadas.join(','),
      fecIni:moment(this.fecIni).format('YYYY-MM-DD'),
      fecFin:moment(this.fecFin).format('YYYY-MM-DD'),
      periodo:moment(this.fecFin).format('YYYY'),
      usuario:this.userData.rut,
      email:this.userData.email,
      tipo:'pdf'
      
    }
    this.spinner.show();
    this.cartolaService.iniciarCartolaMasiva(filtros).subscribe((result) =>{
      this.toastr.success(result.data, GLOBALS.TOASTER.TITLE_OK);
      this.spinner.hide();
      this.router.navigate(['rep-cartola-actualizada/ejecuciones']);
    })
  }

  iniciarEnvioMasivoExcel(){
    const filtros = {
      cliComercial:this.empresasSucursalSeleccionadas.length > 0 ? this.userData.clienteComercialSeleccionado :'TODOS',
      empresas:this.empresasSucursalSeleccionadas.join(','),
      fecIni:moment(this.fecIni).format('YYYY-MM-DD'),
      fecFin:moment(this.fecFin).format('YYYY-MM-DD'),
      periodo:moment(this.fecFin).format('YYYY'),
      usuario:this.userData.rut,
      email:this.userData.email,
      tipo:'excel'
    }
    this.spinner.show();
    this.cartolaService.iniciarCartolaMasiva(filtros).subscribe((result) =>{
      this.toastr.success(result.data, GLOBALS.TOASTER.TITLE_OK);
      this.spinner.hide();
    })
  }

  mySelectionKey(context: RowArgs) {
    return context.dataItem;
  }

  openModalPDF(refModal){
    const modalref = this.modalService.open(refModal);
    modalref.result.then((result) => {
      this.reportepdf.saveAs('Cartola Actualizada.pdf');
      this.filtrosSeccionesCartola = [];
    });
  }

  isSelected(codigo:number):boolean {
    return !!this.filtrosSeccionesCartola.find((seccion) => seccion.codigo == codigo);
  }
}
