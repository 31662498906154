import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Observable, of } from "rxjs";
import {
  CheckableSettings,
  TreeItemLookup,
} from "@progress/kendo-angular-treeview";

import { EmpresasSucursalModel } from "src/app/models/empresasSucursal.model";
import { TreeNode } from "src/app/interfaces/common.interface";

@Component({
  selector: "app-selector-empresa",
  templateUrl: "./selector-empresa.component.html",
  styleUrls: ["./selector-empresa.component.scss"],
})
export class SelectorEmpresaComponent implements OnInit, OnChanges {
  @Input() empresas: EmpresasSucursalModel[] = [];
  @Output() seleccionEmpresas = new EventEmitter<number[]>();
  nodes: TreeNode[] = [];
  empresaSucursalSeleccionadas: FormControl;

  static customMessages = {
    selectAll: 'Seleccionar todo', 
    clear: 'Borrar selección',
  };
  constructor(private fb: UntypedFormBuilder) {
    this.empresaSucursalSeleccionadas = this.fb.control([], [Validators.required]);
  }

  get empresasSeleccionadas(){
    const sucursalEmpresa:number[] = [];
    for(const empresa of this.empresaSucursalSeleccionadas.value){
      const [empresaCodigo, sucursalCodigo] = empresa.value.toString().split(',');
      sucursalEmpresa.push(parseInt( sucursalCodigo ? sucursalCodigo : empresaCodigo));
      if(empresa.items && empresa.items.length > 0){
        for(const sucursal of empresa.items){
          const [ _, sucursalCodigo] = sucursal.value.toString().split(',');
          sucursalEmpresa.push(parseInt( sucursalCodigo));
        }
      }
    }
    return [...new Set(sucursalEmpresa)].sort((a,b) => a - b);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.empresas) {
      this.makeNodes();
    }
  }
  ngOnInit(): void {
    this.makeNodes();
    this.empresaSucursalSeleccionadas.valueChanges.subscribe((value: TreeNode[]) => {
      console.log('Empresa', value);
      const sucursalEmpresa:number[] = [];
      for(const empresa of value){
        const [empresaCodigo, sucursalCodigo] = empresa.value.toString().split(',');
        sucursalEmpresa.push(parseInt( sucursalCodigo ? sucursalCodigo : empresaCodigo));
        if(empresa.items && empresa.items.length > 0){
          for(const sucursal of empresa.items){
            const [ _, sucursalCodigo] = sucursal.value.toString().split(',');
            sucursalEmpresa.push(parseInt( sucursalCodigo));
          }
        }
      }
      this.seleccionEmpresas.emit([...new Set(sucursalEmpresa)].sort((a,b) => a - b));
    })
  }

  private makeNodes(){
    /* Genera el arbol ramificado */
    this.nodes = this.empresas.reduce((acc: TreeNode[], empresa) => {
      const existe = acc.find((item) => item.value === empresa.codigoEmpresa);
      if (existe) {
        existe.items.push({
          text: empresa.sucursal,
          value: empresa.codigoEmpresa + ',' + empresa.codigoSucursal,
        });
      } else {
        const node: TreeNode = {
          text: empresa.rutRazonSocial,
          value: empresa.codigoEmpresa,
          items: [],
        };
        if (empresa.codigoEmpresa !== empresa.codigoSucursal) {
          node.items.push({
            text: empresa.sucursal,
            value: empresa.codigoEmpresa + ',' + empresa.codigoSucursal,
          });
        }
        acc.push(node);
      }
      return acc;
    }, []);

    console.log('nodes', this.nodes);
  }

}
