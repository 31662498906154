<main>
    <div class="container-fluid mb-4 fondo-gris-ok" style="margin-top:-20px;">
        <div class="container">
            <div class="row">
                <div class="col-md-7 col-12 mt-3">
                    <h1>Reporte Historial del Participante</h1>
                </div>
                <div class="col-md-5 col-12">
                    <div aria-label="breadcrumb" class="text-right float-right">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href={{urlGestion}}>Home</a></li>
                            <li class="breadcrumb-item"><a [routerLink]="['/home-reportes']">Reportes</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Reporte Historial del Participante</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-12 form-group">
                    <div class="row">
                        <div class="col-12" style="margin-bottom: 25px;">
                            <label>Selecciona una o varias Empresas y/o Sucursales, el Período y el RUT del Participante a consultar, luego presiona la tecla Enter o el botón con el icono de Lupa:</label>
                            <label>(Para poder seleccionar un período o ingresar el RUT del participante, debe seleccionar una Empresa o Sucursal en primer lugar)</label>
                        </div>
                    </div>
                    <div class="row" style="margin-bottom: 20px;">
                        <div class="col-12 col-md-6">
                            <app-selector-empresa [empresas]="empresas" (seleccionEmpresas)="seleccionarEmpresasSucursales($event)"></app-selector-empresa>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="row">
                                <div class="col-12 col-md-6 form-group">
                                    <label>Fecha Inicio Desde:</label>
                                    <kendo-datepicker class="form-control" placeholder="Ingresa" [(ngModel)]="fecIni" [disabled]="empresasSucursalSeleccionadas.length === 0 || sinPVs" [min]="min" [max]="max" (keydown)="filtrarReporteKeydown($event)">
                                        <kendo-datepicker-messages today="Hoy">
                                        </kendo-datepicker-messages>
                                    </kendo-datepicker>
                                    <span *ngIf="errorFiltro.fecIni" class="form-error">
                                      Debes seleccionar Fecha Desde
                                  </span>
                                </div>
                                <div class="col-12 col-md-6 form-group">
                                    <label>Fecha Término Hasta:</label>
                                    <kendo-datepicker class="form-control" placeholder="Ingresa" [(ngModel)]="fecFin" [disabled]="empresasSucursalSeleccionadas.length === 0 || sinPVs" [min]="min" [max]="max" (keydown)="filtrarReporteKeydown($event)">
                                        <kendo-datepicker-messages today="Hoy">
                                        </kendo-datepicker-messages>
                                    </kendo-datepicker>
                                    <span *ngIf="errorFiltro.fecFin" class="form-error">
                                      Debes seleccionar Fecha Hasta
                                  </span>
                                </div>
                                <div class="col-12 col-md-6 form-group">
                                    <label> DNI: </label>
                                    <div class="custom-control custom-radio">
                                        <label *ngFor="let ext of esExtranjeroOpts" [for]="ext.nombre" style="margin-right:40px;padding-top:3px;">
                                            <input type="radio" name="esExtranjero" style="outline:0px" [id]="ext.nombre" [value]="ext.valor" [(ngModel)]="esExtranjero">
                                            {{ext.nombre}}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 form-group">
                                    <label> Formato: </label>
                                    <div class="custom-control custom-radio">
                                        <label *ngFor="let form of formatoOpts" [for]="form.nombre" style="margin-right:40px;padding-top:3px;">
                                            <input type="radio" name="formato" style="outline:0px" [id]="form.nombre" [value]="form.valor" [(ngModel)]="formato">
                                            {{form.nombre}}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-8 form-group" *ngIf="!esExtranjero">
                                    <label>RUT Participante</label>
                                    <input type="text" class="form-control" placeholder="Ingrese RUT del participante, ej: 11111111-1" [(ngModel)]="rutParticipante" maxlength="10" [disabled]="empresasSucursalSeleccionadas.length === 0 || sinPVs" />
                                    <span *ngIf="errorFiltro.rutParticipante" class="form-error">
                                        Debe ingresar un RUT válido
                                    </span>
                                </div>
                                <div class="col-12 col-md-8 form-group" *ngIf="esExtranjero">
                                    <label>DNI Participante</label>
                                    <input type="text" class="form-control" placeholder="Ingrese DNI del participante" [(ngModel)]="rutParticipante" maxlength="100" [disabled]="empresasSucursalSeleccionadas.length === 0 || sinPVs" />
                                    <span *ngIf="errorFiltro.rutParticipante" class="form-error">
                                        Debe ingresar un DNI válido
                                    </span>
                                </div>
                                <div class="col-12 col-md-2 form-group mt-1">
                                    <a *ngIf="permisosModulosAcciones.REPHISTPART.acciones.includes('REPHISTPART_Acceder')" (click)="filtrarReporte()" data-toggle="tooltip" ngbTooltip="Descargar Reporte" class="btn btn-primary" style="margin-top:20px;">
                                        <img src="../assets/img/busca.png" class="icon-md">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>