<div class="row">
    <div class="col-12 form-group">
        <label>Empresas y Sucursales:</label>

        <!-- <kendo-expansionpanel [expanded]="expanded">
            <ng-template kendoExpansionPanelTitleDirective>
              <div class="header-content" (click)="expanded = !expanded">
                <span>{{empresasSeleccionadas.length === 0 ? 'Selecciona' : empresasSeleccionadas.length === 1 ? '1 Cliente Sucursal seleccionado' : empresasSeleccionadas.length + ' Clientes Sucursales seleccionados'}}</span>
              </div>
            </ng-template>
            <form [formGroup]="filtrosForm">
                <label>Buscar Empresas y/o Sucursales:</label>
                <input formControlName="filtro" type="text" class="form-control" placeholder="Ingresa RUT o Nombre para filtrar"> 
                <kendo-treeview [nodes]="parsedData" [children]="children" [hasChildren]="hasChildren" textField="text" [kendoTreeViewCheckable]="checkableSettings" [(checkedKeys)]="checkedKeys" (checkedChange)="handleChecking($event)">
                </kendo-treeview>
            </form>
        </kendo-expansionpanel> -->
            <kendo-multiselecttree
                #multiselecttree
                kendoMultiSelectTreeSummaryTag
                [kendoMultiSelectTreeHierarchyBinding]="nodes"
                [filterable]="true"
                [formControl]="empresaSucursalSeleccionadas"
                childrenField="items"
                textField="text"
                valueField="value"
                fillMode="none"
                class="form-control"
                placeholder="Selecciona"
            >
            <ng-template check>
                Seleccionar todo
            </ng-template>
            <ng-template kendoMultiSelectTreeGroupTagTemplate let-dataItems>
                <span>{{empresasSeleccionadas.length > 1 ? empresasSeleccionadas.length + ' Clientes Sucursales seleccionados' : '1 Cliente Sucursal seleccionado'}}</span>
              </ng-template>
            </kendo-multiselecttree>
    </div>
</div>
<span *ngIf="empresaSucursalSeleccionadas.touched && !empresaSucursalSeleccionadas.valid" class="form-error">
    Debes seleccionar al menos una Empresa o Sucursal.
</span>