import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';
import { CheckableSettings, TreeItemLookup } from '@progress/kendo-angular-treeview';
import { Observable, of } from 'rxjs';
import * as moment from 'moment';
import {ClienteAuditoria} from 'otic-cliente-auditoria';
import {UserDataLocalStorageModel} from 'src/app/models/userDataLocalStorage';
// Services
import { UserLoginService } from '../../services/user-login.service';
import { ClienteComercialService } from '../../services/cliente-comercial.service';

// Models
import { EmpresasSucursalModel } from 'src/app/models/empresasSucursal.model';

// Shared
import { environment } from '../../../environments/environment';
import { GLOBALS } from '../../shared/globals.shared';
import { ConsolidadoService } from '../../services/consolidado.service';
import { PermisosCliComLocalStorageModel } from 'src/app/models/permisosCliComLocalStorage';

@Component({
    selector: 'app-rep-historial-participantes',
    templateUrl: './rep-historial-participante.component.html',
    styleUrls: ['./rep-historial-participante.component.scss']
})
export class RepHistorialParticipanteComponent implements OnInit {
    urlGestion: string = environment.url_gestor;

    min: Date = null;
    max: Date = null;
    fechaActual: string = [
        new Date().getDate().toString().length === 1 ? '0' + new Date().getDate().toString() : new Date().getDate().toString(),
        (new Date().getMonth() + 1).toString().length === 1 ?
            '0' + (new Date().getMonth() + 1).toString() : (new Date().getMonth() + 1).toString(),
        new Date().getFullYear()].join('/');

    sinPVs = false;
    userData: UserDataLocalStorageModel = {};
    empresasSucursales: any[] = [];
    parsedData: any[] = [];
    checkedKeys: any[] = [];
    searchTerm = '';
    listadoFinal: any;
    nombreCC: string;
    empresasSucursalSeleccionadas: number[] = [];
    empresas: EmpresasSucursalModel[] = [];
    fecIni: Date = null;
    fecFin: Date = null;
    rutParticipante: string = null;
    esExtranjero:boolean = false;
    formato:string = 'xlsx';

    errorFiltro = {
        empresaSucursal: false,
        fecIni: false,
        fecFin: false,
        rutParticipante: false
    };

    consolidado: any[] = [];
    permisosModulosAcciones: any = {};
    permisos: Array<PermisosCliComLocalStorageModel>;

    checkNoEnviarCorreo = false;

    esExtranjeroOpts=[
        {nombre:'SI',valor:true},
        {nombre:'NO',valor:false}
    ];

    formatoOpts=[
        {nombre:'EXCEL',valor:'xlsx'},
        {nombre:'PDF',valor:'pdf'}
    ];

    constructor(
        private spinner: NgxSpinnerService,
        private toastr: ToastrService,
        private router: Router,
        private localStorageService: LocalStorageService,
        private clienteComercialService: ClienteComercialService,
        private userLoginService: UserLoginService,
        private consolidadoService: ConsolidadoService) {
        console.log('RepAportesImputadosComponent: constructor');
    }

    /**
     * OnInit Event
     */
    ngOnInit() {
        console.log('RepAportesImputadosComponent: OnInit');

        this.userLoginService.isAuthenticated(this);
        this.userData = this.localStorageService.get('userData');
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (isLoggedIn) {
            this.router.events.subscribe((evt) => {
                if (!(evt instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });

            this.permisosModulosAcciones = this.localStorageService.get('PERMISOSMODULOSACCIONES') || {};
            this.permisos = this.localStorageService.get('permisos');

            if (this.permisosModulosAcciones.REPHISTPART) {
                this.initListadoEmpresas();
            } else {
                this.toastr.error(`No estás autorizado para ingresar a esta página`, GLOBALS.TOASTER.TITLE_ERROR);
                this.router.navigate(['/']);
            }

        }
    }

    /**
     * Inicializa el listado de Empresas
     */
    initListadoEmpresas() {
        this.spinner.show();

        const sucursalesAutorizadas = this.permisos.map((item) => item.data.sucursalCodigo).join(',');

        if (sucursalesAutorizadas) {
            this.clienteComercialService.listarEmpresasSucursales(sucursalesAutorizadas)
                .subscribe(async (result) => {

                        if (result.rows.length > 0) {
                            this.empresas = result.rows.map((empresa) => new EmpresasSucursalModel(empresa));

                            let index = 1;
                            let codigoEmpresa: number;
                            let rama: any;
                            for (const empresa of this.empresas) {
                                if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa === empresa.codigoSucursal) {
                                    if (index !== 1) {
                                        this.empresasSucursales.push(rama);
                                    }

                                    codigoEmpresa = empresa.codigoEmpresa;
                                    rama = {
                                        text: empresa.rutRazonSocial,
                                        value: empresa.codigoEmpresa,
                                        items: []
                                    };
                                } else if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa !== empresa.codigoSucursal) {
                                    if (index !== 1) {
                                        this.empresasSucursales.push(rama);
                                    }

                                    codigoEmpresa = empresa.codigoEmpresa;
                                    rama = {
                                        text: empresa.rutRazonSocial,
                                        items: [{
                                            text: empresa.sucursal,
                                            value: empresa.codigoEmpresa
                                        }]
                                    };
                                } else {
                                    rama.items.push({
                                        text: empresa.sucursal,
                                        value: empresa.codigoEmpresa
                                    });
                                }

                                if (index === this.empresas.length) {
                                    this.empresasSucursales.push(rama);
                                }

                                index++;
                            }

                            this.parsedData = this.empresasSucursales;
                            console.log('this.parsedData', this.parsedData);
                            this.listadoFinal = [{
                                text: 'Seleccionar Todos',
                                value: 'Todos',
                                items: this.parsedData.map(x => {
                                    return {
                                        text: x.text,
                                        value: x.value,
                                        items: x.items
                                    };
                                })
                            }];
                            console.log('prueba', this.listadoFinal);

                        } else {
                            this.toastr.warning(`No hay información de Empresas / Sucursales para Reportes`, `Advertencia`);
                        }

                        this.spinner.hide();
                    }, error => {
                        console.log(error);
                        this.spinner.hide();
                        this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
                    }
                );
        } else {
            this.toastr.warning(`No hay Empresas / Sucursales en Cliente Comercial`, `Advertencia`);
            this.spinner.hide();
        }
    }

    /**
     * Busca las fechas de Inicio y Fin de las PV al cambiar la selección
     */
    inicializaFechas() {
        this.fecIni = null;
        this.fecFin = null;
        this.sinPVs = false;

        this.errorFiltro = {
            empresaSucursal: false,
            fecIni: false,
            fecFin: false,
            rutParticipante: false
        };

        if (this.empresasSucursalSeleccionadas.length > 0) {
            const rutsEmpresa = this.empresasSucursalSeleccionadas.join(',');

            this.spinner.show();
            this.consolidadoService.obtenerFechasPVs(rutsEmpresa).subscribe(
                (result: any) => {
                    this.spinner.hide();

                    if (result.minanio === null) {
                        this.sinPVs = true;
                    } else {
                        this.min = new Date(result.minanio.substring(0, 19));
                        this.max = new Date(result.maxanio.substring(0, 19));
                        this.fecIni = this.min;
                        this.fecFin = this.max;
                    }
                },
                error => {
                    console.log(error);
                    this.spinner.hide();
                    this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
                }
            );
        } else {
            if (this.empresasSucursales.length > 0) {
                this.errorFiltro.empresaSucursal = true;
            }
        }
    }

    validarRut(rutCompleto: string): boolean {
        rutCompleto = rutCompleto.replace('‐', '-');

        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto )) {
            return false;
        }

        const tmp = rutCompleto.split('-');
        let digv = tmp[1];
        const rut = tmp[0];
        if ( digv == 'K' ) {digv = 'k'; }
        return (this.checkDV(rut) == digv );
    }

    checkDV(rutPartNumber: any): any {
        let M = 0;
        let S = 1;
        for (; rutPartNumber; rutPartNumber = Math.floor(rutPartNumber / 10)) {
            S = (S + rutPartNumber % 10 * ( 9 - M ++ % 6 )) % 11;
        }
        return S ? S - 1 : 'k';
    }

    configuraFechas() {
        /*
        this.spinner.show();

        this.listFecIni = [];
        this.listFecFin = [];

        for (let z = this.minIni; z <= (this.fecFinDesde !== undefined ? this.fecFinDesde : this.maxIni); z++) {
          this.listFecIni.push(z.toString());
        }

        for (let z = (this.fecIniDesde !== undefined ? this.fecIniDesde : this.minIni); z <= this.maxIni; z++) {
          this.listFecFin.push(z.toString());
        }

        this.spinner.hide();
        */
      }

      seleccionarEmpresasSucursales(sucursales:number[]){
        this.empresasSucursalSeleccionadas = sucursales;
        this.inicializaFechas();
      }

    /**
     * Filtra el Reporte
     */
    filtrarReporte() {
        this.errorFiltro = {
            empresaSucursal: false,
            fecIni: false,
            fecFin: false,
            rutParticipante: false
        };

        if (this.empresasSucursalSeleccionadas.length === 0) {
            this.errorFiltro.empresaSucursal = true;
            return;
        } else {
            if (this.fecIni === undefined || this.fecIni === null) {
                this.errorFiltro.fecIni = true;
            }
            if (this.fecFin === undefined || this.fecFin === null) {
                this.errorFiltro.fecFin = true;
            }

            if (this.fecIni === undefined || this.fecIni === null || this.fecFin === undefined || this.fecFin === null) {
                return;
            }

            if (this.rutParticipante === undefined || this.rutParticipante === null) {
                this.errorFiltro.rutParticipante = true;
                return;
            }
        }

        this.nombreCC = this.userData.clienteComercialSeleccionado.nombre;
        
        if(!this.esExtranjero){
            this.rutParticipante.replace('‐', '-');
            const splitRut = this.rutParticipante.split('-');
            this.rutParticipante = parseInt(splitRut[0], 10) + '-' + splitRut[1];
        }

        const filtrosReporte = {
            cliComercial:this.nombreCC,
            empresaSucursal: this.empresasSucursalSeleccionadas.join(','),
            fecIni: moment(this.fecIni).format('L'),
            fecFin: moment(this.fecFin).format('L'),
            rutParticipante: this.rutParticipante,
            usuario: this.userData.rut,
            email: this.userData.email,
            noEnviarCorreo: this.checkNoEnviarCorreo,
            formato: this.formato
        };

        this.spinner.show();
        this.consolidadoService.reporteHistorialParticipanteStart(filtrosReporte).subscribe(
            (result: any) => {
                console.log("Proceso iniciado");
                this.accion('Reporte Historial del Participantes', '', 'Genera Reporte para ' + this.nombreCC);
                this.toastr.success(result, GLOBALS.TOASTER.TITLE_OK);
                this.spinner.hide();
            },
            error => {
                console.log("No es posible descargar el archivo",error);
                this.spinner.hide();
                this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
            }
        );
    }

    /**
     * Filtra Reporte al presionar tecla Enter
     * @param event Evento
     */
    filtrarReporteKeydown(event) {
        if (event.key === 'Enter') {
            this.filtrarReporte();
        }
    }

    async accion(modulo: string, submodulo: string, acc: string){
        const token = await this.localStorageService.get('idToken');
        console.log('token', token);

        ClienteAuditoria({
            sistema: 'GCH Reportes',
            modulo,
            submodulo: submodulo ? submodulo : '',
            usuario: this.userData.rut,
            token,
            accion: acc
        });
    }

}
